import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import DashboardService from "../../service/api/dashboard.service";
import DateFilter from "./date-filter";
import { endOfMonth, format, startOfMonth } from "date-fns";
import cronParser from 'cron-parser';
import AdminCronSettings from "./AdminCronSetting";
import moment from "moment/moment";
import TransactionStatistics from "./TransactionsStatistics";
import { useSelector } from "react-redux";
import './dateFilter.css'
import { Loader } from "../../common/Spinner";
import MerchantFilter from "./merchant-filter";
export default function TransactionMetrics() {
    const skin = useSelector((state) => state.ui.skin);
    const dashboardService = DashboardService();
    const [statistics, setStatistics] = useState(null);
    const today = new Date();
    const initialStartDate = startOfMonth(today);
    const initialEndDate = endOfMonth(today);
    const [merchantId, setMerchantId] = useState('');
    // const [dateRange, setDateRange] = useState({
    //     startDate: format(initialStartDate, 'yyyy-MM-dd'),
    //     endDate: format(initialEndDate, 'yyyy-MM-dd')
    // });

    const [dateRange, setDateRange] = useState({
        startDate: format(today, 'yyyy-MM-dd'),
        endDate: format(today, 'yyyy-MM-dd')
    });
    const [cronExpression, setCronExpression] = useState(localStorage.getItem('cronExpression') || '');
    const timeoutId = useRef(null);
    const axisColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : 'rgb(224, 224, 224)';
    const labelColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : '#41505f';
    useEffect(() => {
        localStorage.setItem('cronExpression', cronExpression);
        getStatistics(); // Initial fetch
        scheduleFetch();

        // Cleanup function to clear timeout if cronExpression or dateRange changes
        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [cronExpression, dateRange,merchantId]);

    // useEffect(() => {
    //     getStatistics();
    // }, [dateRange])

    const scheduleFetch = () => {
        try {
            const adjustedCron = cronExpression.split(' ').slice(1, 6).join(' ');
            const interval = cronParser.parseExpression(adjustedCron);
            const now = new Date();
            const nextExecution = interval.next().toDate();
            const delay = nextExecution - now;
            // console.log(delay, "execution delay", interval, interval.next().toDate(), adjustedCron, cronExpression)
            if (delay > 0) {
                const id = setTimeout(() => {
                    getStatistics();
                    scheduleFetch(); // Schedule next fetch
                }, delay);
                timeoutId.current = id; // Store timeout ID to clear it later
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getStatistics = async () => {
        try {
            const paylaod = {
                ...dateRange,
                merchantId:merchantId
            }
            const res = await dashboardService.getBetTransactionMetrics(paylaod);
            console.log(res.data, "console from transaction metrics");
            setStatistics(res?.data); // Assuming the API response is in the expected format
        } catch (error) {
            console.error("Error fetching bet statistics", error);
        }
    }












    // Dummy data for charts
    // const transactionOverTimeData = {
    //     series: [{
    //         name: "Bets Placed",
    //         data: statistics?.successRate?.map((item) => item.successRate) || [],
    //         type:'datetime'
    //     }],
    //     options: {
    //         chart: {
    //             height: 350,
    //             type: 'line',
    //         },
    //         stroke: {
    //             curve: 'smooth'
    //         },
    //         xaxis: {
    //             categories: statistics?.successRate?.map((item) => moment(item.dateTime).format('YYYY-MM-DD'))||[],
    //         }
    //     }
    // };


    // console.log("Processed Data:", processedData);
    const depositData = statistics?.depositOverTime || [];
    const withdrawalData = statistics?.withdrawalOverTime || [];
    
    const transactionOverTimeData = {

        series: [
            {
                name: "Deposits",
                data: depositData.map((item) => item.count),
            },
            {
                name: "Withdrawals",
                data: depositData.map((depositItem) => {
                    const withdrawalItem = withdrawalData.find((item) => item._id === depositItem._id);
                    return withdrawalItem?.count ?? 0;
                }),
            },
        ],
        options: {
            tooltip: {
                theme: skin == "dark" ? "dark" : "light",
            },
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    autoSelected: 'zoom',
                    show: true, // Ensure the toolbar is enabled
                    tools: {
                        download: true, // Enable the download tool
                    },

                    export: {
                        csv: {
                            filename: 'Transaction Report (Monthly)', // Custom file name
                            headerCategory: 'Date & Time', // Custom header name for category (X-axis)
                            columnHeaders: ['Date & Time', 'Deposits', 'Withdrawals'], // Explicitly set headers for each column
                            dateFormatter(timestamp) { // Custom date format for CSV export
                                return new Date(timestamp)?.toLocaleString();
                            }
                        },
                        svg: {
                            filename: 'Transaction Report (Monthly)', // Custom file name for SVG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        },
                        png: {
                            filename: 'Transaction Report (Monthly)', // Custom file name for PNG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        }
                    },
                },
            },
            stroke: {
                curve: 'smooth'
            },
            legend: {
                show: true,

                labels: {
                    colors: labelColor,  // Conditional color for dark/light theme
                    useSeriesColors: false  // If you want to use the same color as the series
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    radius: 12,
                    offsetX: 0,
                    offsetY: 0
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                }
            },
            xaxis: {
                categories: statistics?.depositOverTime?.map((item) => item?._id) || [],
                axisBorder: {
                    show: true,
                    color: axisColor,  // Conditional color for dark/light theme
                    height: 2,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,  // Conditional color for dark/light theme
                },
                labels: {
                    style: {
                        colors: labelColor,  // Conditional color for dark/light theme
                    }
                }
            },
            yaxis: {
                axisBorder: {
                    show: true,
                    color: axisColor,  // Conditional color for dark/light theme
                },
                axisTicks: {
                    show: true,
                    color: axisColor,  // Conditional color for dark/light theme
                },
                labels: {
                    style: {
                        colors: labelColor,  // Conditional color for dark/light theme
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            grid: {
                show: true,  // Enable grid to allow border customization
                borderColor: axisColor,  // Conditional color for dark/light theme
                strokeDashArray: 0,
                xaxis: {
                    lines: {
                        show: false  // Hide vertical grid lines
                    }
                },
                yaxis: {
                    lines: {
                        show: false  // Hide horizontal grid lines
                    }
                },
                padding: {
                    left: 13,
                    right: 10,
                    top: 0,
                    bottom: 0
                }
            }
        }
    };

    const avgBetSizeData = {
        series: [
            {
                name: "Deposits",
                data: statistics?.depositOverTime.map((item) => item.count),
            },
            {
                name: "Withdrawals",
                data: statistics?.withdrawalOverTime.map((item) => item?.count || '0'),
            },
        ],
        options: {
            tooltip: {
                theme: skin == "dark" ? "dark" : "light",
            },
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    autoSelected: 'zoom',
                    show: true, // Ensure the toolbar is enabled
                    tools: {
                        download: true, // Enable the download tool
                    },

                    export: {
                        csv: {
                            filename: 'Deposit & Withdraw Report (Monthly)', // Custom file name
                            headerCategory: 'Date & Time', // Custom header name for category (X-axis)
                            columnHeaders: ['Date & Time', 'Deposits', 'Withdrawals'], // Explicitly set headers for each column
                            dateFormatter(timestamp) { // Custom date format for CSV export
                                return new Date(timestamp).toLocaleString();
                            }
                        },
                        svg: {
                            filename: 'Deposit & Withdraw Report (Monthly)', // Custom file name for SVG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        },
                        png: {
                            filename: 'Deposit & Withdraw Report (Monthly)', // Custom file name for PNG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        }
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,

                labels: {
                    colors: labelColor,  // Conditional color for dark/light theme
                    useSeriesColors: false  // If you want to use the same color as the series
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    radius: 12,
                    offsetX: 0,
                    offsetY: 0
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                }
            },
            xaxis: {
                categories: statistics?.depositOverTime?.map((item) => item._id) || [],
                axisBorder: {
                    show: true,
                    color: axisColor,  // Conditional color for dark/light theme
                    height: 2,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,  // Conditional color for dark/light theme
                },
                labels: {
                    style: {
                        colors: labelColor,  // Conditional color for dark/light theme
                    }
                }
            },
            yaxis: {
                axisBorder: {
                    show: true,  // Show the axis line on the x-axis
                    color: axisColor,  // Conditional color for dark/light theme
                },
                axisTicks: {
                    show: true,  // Show the ticks on the x-axis
                    color: axisColor,  // Conditional color for dark/light theme
                },
                labels: {
                    style: {
                        colors: labelColor,  // Conditional color for dark/light theme
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            grid: {
                show: true, // Enable grid to allow border customization
                borderColor: axisColor, // Set the color for axis border lines
                strokeDashArray: 0, // Solid border lines
                xaxis: {
                    lines: {
                        show: false // Hide vertical grid lines
                    }
                },
                yaxis: {
                    lines: {
                        show: false // Hide horizontal grid lines
                    }
                },
                padding: {
                    left: 13,
                    right: 10,
                    top: 0,
                    bottom: 0
                }
            },
        }
    };

    const handleDateChange = (range) => {
        setDateRange(range);
    };


    const trasacntionRateData = statistics?.successRate?.map((item) => {

        const timestamp = new Date(item._id.dateTime).getTime(); // Convert dateTime to timestamp
        const value = Number(item.totalTransactions); // Ensure successRate is a number
        if (isNaN(timestamp) || isNaN(value)) {
            console.warn("Invalid data point:", { dateTime: item.dateTime, successRate: item.successRate });
        }
        return {
            x: timestamp,
            y: value,

        };
    }).filter(point => !isNaN(point.x) && !isNaN(point.y)) || [];

    const trasacntionSuccessRateData = statistics?.successRate?.map((item) => {
       
        const timestamp = new Date(item._id.dateTime).getTime(); // Convert dateTime to timestamp
        const value = Number(item.successRate); // Ensure successRate is a number
        if (isNaN(timestamp) || isNaN(value)) {
            console.warn("Invalid data point:", { dateTime: item._id.dateTime, successRate: item.successRate });
        }
        return {
            x: timestamp,
            y: value,
        };
    }).filter(point => !isNaN(point.x) && !isNaN(point.y)) || [];

// Check if the dataset is empty
const isDataEmpty = !trasacntionRateData || trasacntionRateData.length === 0;

// Set current date in the desired format if data is empty
const currentDate = new Date().toISOString();
    const TransactionsSuccessRate = {
        series: [
            {
                name: 'Total Transactions',
                data: trasacntionRateData,
            },
            {
                name: 'Success Rate',
                data: trasacntionSuccessRateData,
            }
        ],
        colors: ['#506fd9', '#85b6ff'],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true,
            },
            toolbar: {
                autoSelected: 'zoom',
                show: true, // Ensure the toolbar is enabled
                tools: {
                    download: true, // Enable the download tool
                },

                export: {
                    csv: {
                        filename: 'Hourly Success Rate', // Custom file name
                        headerCategory: 'Date & Time', // Custom header name for category (X-axis)
                        columnHeaders: ['Date & Time', 'Total Transaction', 'Success Rate'], // Explicitly set headers for each column
                        dateFormatter(timestamp) { // Custom date format for CSV export
                            return new Date(timestamp).toLocaleString();
                        }
                    },
                    svg: {
                        filename: 'Hourly Success Rate', // Custom file name for SVG export
                        width: 800, // Custom width
                        height: 600, // Custom height
                        backgroundColor: '#000000', // Custom background color
                    },
                    png: {
                        filename: 'Hourly Success Rate', // Custom file name for PNG export
                        width: 800, // Custom width
                        height: 600, // Custom height
                        backgroundColor: '#000000', // Custom background color
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.6, // Adjusted for better visibility
                opacityTo: 0.2,  // Adjusted for better visibility
                stops: [0, 90, 100],
            },
        },
        grid: {
            show: true,
            borderColor: axisColor,
            strokeDashArray: 0,
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            padding: {
                left: 10,
                right: 10,
                top: 0,
                bottom: 0,
            },
        },
        yaxis: [
            {
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0);
                    },
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                    },
                },
                title: {
                    text: 'Total Transactions',
                    style: {
                        color: labelColor,
                    },
                },
                axisBorder: {
                    show: true,
                    color: axisColor,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,
                },
            },
            {
                opposite: true,
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0);
                    },
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                    },
                },
                title: {
                    text: 'Success Rate',
                    style: {
                        color: labelColor,
                    },
                },
                axisBorder: {
                    show: true,
                    color: axisColor,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,
                },
            },
        ],
        xaxis: {
            type: 'datetime',
            categories: isDataEmpty ? [currentDate] : '',
            axisBorder: {
                show: true,
                color: axisColor,
                height: 2,
            },
            axisTicks: {
                show: true,
                color: axisColor,
            },
            labels: {
                formatter: function (value) {
                    const date = new Date(value);
                    // Display only time in the format "HH:MM:SS"
                    return date.toLocaleTimeString('en-US', { hour12: false });
                    // const date = new Date(value);
                    // Example: Format as "Tuesday, August 29, 2023, 14:30:00"
                    // return date.toString();
                    // return date.toLocaleString('en-US', {
                    //     weekday: 'long',
                    //     year: 'numeric',
                    //     month: 'long',
                    //     day: 'numeric',
                    //     hour: '2-digit',
                    //     minute: '2-digit',
                    //     second: '2-digit',
                    //     hour12: false
                    // });
                },
                style: {
                    colors: labelColor,
                },
            },
        },
        tooltip: {
            theme: skin == "dark" ? "dark" : "light",
            y: {
                formatter: function (val) {
                    return val.toFixed(0);
                },
            },
            x: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    const date = new Date(value);
                    // Example: Format as "Tuesday, August 29, 2023, 14:30:00"
                    // return date.toString();
                    return date.toLocaleString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false
                    });
                }
            },
            style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
            },
        },
        legend: {
            show: true,
            labels: {
                colors: labelColor,
                useSeriesColors: false,
            },
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                radius: 12,
                offsetX: 0,
                offsetY: 0,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
    };




    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="/bet-statics">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Financial Metrics</li>
                        </ol>
                        <h4 className="main-title mt-2 mb-0">Financial Metrics</h4>
                    </div>
                    <div className="d-flex flex-wrap gap-2 mt-3 mt-md-0">
                        <MerchantFilter merchantId={merchantId} setMerchantId={ setMerchantId} />
                        <DateFilter onDateChange={handleDateChange} />
                        <AdminCronSettings onSave={(expression) => setCronExpression(expression)} />
                    </div>
                </div>

                {statistics ? <><TransactionStatistics statistics={statistics} />
                    <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                        <Col className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Hourly Success Rate
                                    </Card.Title>
                                    <ReactApexChart options={TransactionsSuccessRate} series={TransactionsSuccessRate.series} type="area" height={350} />
                                    {/* <ReactApexChart options={transactionOverTimeData?.options} series={transactionOverTimeData?.series} type="pie" height={350} /> */}
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                    <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                        <Col lg={6} md={12} className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Transactions Report (Monthly)
                                    </Card.Title>
                                    {/* <ReactApexChart options={options} series={options.series} type="area" height={350} /> */}
                                    <ReactApexChart options={transactionOverTimeData?.options} series={transactionOverTimeData?.series} type="line" height={350} />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={12} className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Deposit & Withdraw Report (Monthly)
                                    </Card.Title>
                                    <ReactApexChart options={avgBetSizeData?.options} series={avgBetSizeData?.series} type="bar" height={350} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row></> : <>
                    <div className="d-flex justify-content-center align-content-center " style={{ height: '60vh' }} >
                        <Loader />
                    </div>
                </>}

                {/* <Footer /> */}
            </div>
        </React.Fragment>
    );
}
