
import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import UserService from "../../service/api/user.service ";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import "./user.css";
import { Button, Card, Row, Col, Nav, ListGroup, Table, Badge, Modal, Form } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import CurrencyService from "../../service/api/currency.service";
import TransactionService from "../../service/api/transaction.service";
import globalSwal from "../../common/swalService";
import TableFilter from "../../common/filter";
import formatDateTime from "../../helper/DateFormate";


export default function UserList() {
  const userService = UserService();
  const authService = AuthService();
  const transactionService = TransactionService();
  const currencyService = CurrencyService();
  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [staffList, setStaffList] = useState([]);
  const [currencyList,setCurrencyList]=useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: "",
    limit: 10,
    merchantId:"",
  });
  const [isHidden, setIsHidden] = useState(false);

  const [columns, setColumns] = useState([
    "name",
    "username",
    "merchantId",
    "email",
    "phone",
    "createdAt",
    "status",
    "isEmailVerified",
    "isBettingAllowed",
    "isLoginAllowed",
    "isWithdrawalAllowed",
    "id",
  ]);

  // New state variables for Add Amount Modal
  const [showAddAmountModal, setShowAddAmountModal] = useState(false);
  const [addAmountForm, setAddAmountForm] = useState({
    userId: "",
    currencyId: "",
    amount: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    authService.getLoginData();
    getCurrencyList();
  }, []);

  useEffect(() => {
    getList();

  }, [pagination.searchBy, pagination.page,pagination.merchantId, pagination.toDate]);


  const getCurrencyList = async () => {
    try {
      const res = await currencyService.getCurrencyList({
        page: pagination?.page,
        limit: pagination?.limit,
        searchBy: pagination?.searchBy,
        
      });
      if (res?.code === 200) {
        setCurrencyList(res?.data?.docs);
       
      } else {
        console.error(res?.message);
        toast.error(res?.message || "Failed to fetch user list.");
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
      toast.error("An unexpected error occurred while fetching user list.");
    }
  }


  const getList = async () => {
    try {
      const res = await userService.getUser({
        page: pagination?.page,
        limit: pagination?.limit,
        searchBy: pagination?.searchBy,
        merchantId: pagination?.merchantId,
        fromDate: pagination?.fromDate ? pagination?.fromDate : "",
        toDate: pagination?.toDate ? pagination?.toDate : "",
      });
      if (res?.code === 200) {
        setStaffList(res?.data?.docs);
        setPagination((prevPagination) => ({
          ...prevPagination,
          pages: res.data.pages,
          totalDocs: res.data.totalDocs,
          limit: res.data.limit,
        }));
      } else {
        console.error(res?.message);
        toast.error(res?.message || "Failed to fetch user list.");
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
      toast.error("An unexpected error occurred while fetching user list.");
    }
  };

  const getStatusBadge = (status, field) => {
    if(field == "status") {
      if (status === 1) {
        return (
          <Badge bg={"success"} className="badge badge-success">
            Active
          </Badge>
        );
      } else if (status === 0) {
        return (
          <Badge bg={"danger"} className="badge badge-danger">
            Inactive
          </Badge>
        );
      }
    }else {
      if (status) {
        return (
          <Badge bg={"primary"} className="badge badge-success">
            Yes
          </Badge>
        );
      } else if (!status) {
        return (
          <Badge bg={"warning"} className="badge badge-danger">
            No
          </Badge>
        );
      }
    }
    
  };

  const getActionDropdown = (id, status, username) => {
    console.log("Getting action dropdown",id,status,username)
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => handleWalletView(id)}>
            View Wallet
          </Dropdown.Item>
          {/* <Dropdown.Item onClick={() => handleBankView(id)}>
            View Bank
          </Dropdown.Item> */}
          {/* <Dropdown.Item onClick={() => handleAddAmount(id,username)}>
            Add Amount
          </Dropdown.Item> */}
          <Dropdown.Item onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => handleStatusUpdate(id, status)}>
            {status === 1 ? "Deactivate" : "Activate"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleUpdate = (id) => {
    const state = { id: id };
    navigate(`/user-management/edit`, { state });
  };

  const handleView = (id) => {
    const state = { id: id };
    navigate(`/user-management/view`, { state });
  };

  const handleWalletView = (id) => {
    const state = { id: id };
    navigate("/user-management/view-wallet", { state });
  };

  const handleBankView = (id) => {
    const state = { id: id };
    navigate("/user-management/view-bank", { state });
  };

  const handleStatusUpdate = async (id, currentStatus) => {
    try {

      globalSwal.confirm({
        title: "Are you sure?",
        text: "Do you really want to change the current status?",
        onConfirm: async() => {
          const res = await userService.updateUser(id, {
            status: currentStatus === 1 ? 0 : 1,
          });
          if (res?.code === 200) {
            globalSwal.success({
              title: "Success!",
              text: "Status has been changed successfully.",
            });
            toast.success(res?.message || "Status updated successfully.");
            getList();
          } else {
            toast.error(res?.message || "Failed to update status.");
          }
          
        },
      });
      
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("An unexpected error occurred while updating status.");
    }
  };

  const handleDelete = async (id) => {
    try {
     
      globalSwal.confirm({
        title: "Are you sure?",
        text: "Do you really want to delete?",
        onConfirm: async () => {
  
  
          const res = await userService.deleteStaff(id);
          if (res?.code === 200) {
            globalSwal.success({
              title: "Success!",
              text: "User has been deleted successfully.",
            });
            toast.success(res?.message);
            getList();
            setPagination({ ...pagination, page: 1 })
          } else {
            toast.error(res?.message || "Failed to delete.");
          }
  
        },
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An unexpected error occurred while deleting user.");
    }
  };

  // Handle Add Amount Modal Open
  const handleAddAmount = (id,username) => {
    setAddAmountForm({
      userId: id,
      username:username,
      currencyId: "",
      amount: "",
    });
    setShowAddAmountModal(true);
  };

  // Handle Add Amount Form Change
  const handleAddAmountChange = (e) => {
    const { name, value } = e.target;
    setAddAmountForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // Handle Add Amount Form Submission
  const handleAddAmountSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const { userId, currencyId, amount } = addAmountForm;

    // Basic validation
    if (!currencyId || !amount) {
      toast.error("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    try {
      const res = await transactionService.manualAddAmount({
        userId,
        currencyId,
        amount,
      });
      if (res?.code === 200) {
        toast.success(res?.message || "Amount added successfully.");
        setShowAddAmountModal(false);
        getList();
      } else {
        toast.error(res?.message || "Failed to add amount.");
      }
    } catch (error) {
      console.error("Error adding amount:", error);
      toast.error("An unexpected error occurred while adding amount.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setPagination((prev) => ({ ...prev, searchBy: value, page: 1 }));
  };

  const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);

  // Currency options (This should ideally come from an API or constants file)
  const currencyOptions = [
    { id: "USD", name: "US Dollar" },
    { id: "EUR", name: "Euro" },
    { id: "GBP", name: "British Pound" },
    // Add more currencies as needed
  ];

  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "status") {
      return {
        header: "Status",
        accessor: column,
        Cell: ({ value }) => getStatusBadge(value, "status"),
      };
    } else if (column.toLowerCase() === "isemailverified") {
      return {
        header: "Email Verified",
        accessor: column,
        Cell:( { value, row }) =>getStatusBadge(row.isVerifyEmailOtp, "email"),
      };
    }
    else if (column.toLowerCase() === "createdat") {
      return {
        header: "Created At",
        accessor: column,
        Cell: ({ value }) => {
          console.log("value", value, column);
          return (
            <span className="text-nowrap">{formatDateTime(value) || "N/A"}</span>
          )
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "isbettingallowed") {
      return {
        header: "Bet Allowed",
        accessor: column,
        Cell:( { value, row }) =>getStatusBadge(value, ""),
      };
    }
      
    else if (column.toLowerCase() === "iswithdrawalallowed") {
      return {
        header: "Withdrawal Allowed",
        accessor: column,
        Cell:( { value, row }) =>getStatusBadge(value, ""),
      };
    }
      
    else if (column.toLowerCase() === "isloginallowed") {
      return {
        header: "Login Allowed",
        accessor: column,
        Cell:( { value, row }) =>getStatusBadge(value, ""),
      };
    }
    
    else if (column.toLowerCase() === "id") {
      return {
        header: "Actions",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row?.status,row?.username),
      };
    }
    else if (column.toLowerCase() === "username") {
      return {
        header: "Username",
        accessor: column,
        Cell: ({ value }) => value?.toUpperCase() || 'N/A',
      };
    }
    else if (column.toLowerCase() === "merchantid") {
      return {
        header: "Merchant",
        accessor: column,
        Cell: ({ value }) => {
          return (
            <p className="text-truncate m-0 mw-50">
             {value?.id?( <a
                className="cursor-pointer "
                onClick={() => {
                
                  const state = { id: value.id };
                  navigate(`/merchant-management/view`, { state });
                }}
              >
                {(value?.name)?.toUpperCase() || "N/A"}
              </a>):"N/A"}
            </p>
          );
        },
      };
    }
    else {
      return {
        header: column.charAt(0).toUpperCase() + column.slice(1),
        accessor: column,
        Cell: ({ value }) => {
          return <span className="text-nowrap">
            {value || "N/A"}
          </span>
        },
      };
    }
  });


  const [showPicker, setShowPicker] = useState(false);
  const [state, setState] = useState([
    {
      startDate: null,
      // endDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const clearRange = () => {
    setState([{ startDate: null, endDate: null, key: "selection" }]);
    setShowPicker(false);
  };
  const handleRangeChange = (ranges) => {
    setState([ranges.selection]);
    // setShowPicker(false); // Hide the picker after selecting the date range
  };
  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
           
              <div className="d-flex justify-content-between align-items-center">
              <PageBreadcrumb title="Users" />
                <div>
                  <Button className="" onClick={() => setIsHidden(!isHidden)}>
                    Filter
                  </Button>
                </div>
              </div>
            <div className={`${!isHidden ? "d-none" : ""} `}>
              {/* Search Input */}
                <TableFilter
                  handleRangeChange={handleRangeChange}
                  handleSearchChange={handleSearchChange}
                  setPagination={setPagination}
                  setShowPicker={setShowPicker}
                state={state}
                tableType={"user-list"}
                  // transactionType={transactionType}
                  clearRange={clearRange}
                  // userList={userList}
                  userId={''}
                  status={''}
                  showPicker={showPicker}
                />
              </div>
          </Card.Header>
          <Card.Body>
            <TableWithPagination
              data={staffList}
              itemsPerPage={pagination.limit}
              columns={columnsWithHTML}
              currentPage={pagination.page}
              totalData={pagination.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              handleSearchChange={debouncedHandleSearchChange}
              moduleId={3}
              
            />
          </Card.Body>
        </Card>

        {/* Add Amount Modal */}
        <Modal
          show={showAddAmountModal}
          onHide={() => setShowAddAmountModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Amount</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleAddAmountSubmit}>
            <Modal.Body>
              <Form.Group controlId="formUserId">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="userId"
                  value={addAmountForm.username}
                  readOnly
                />
              </Form.Group>

              <Form.Group controlId="formCurrencyId" className="mt-3">
                <Form.Label>Currency</Form.Label>
                <Form.Select
                  name="currencyId"
                  value={addAmountForm.currencyId}
                  onChange={handleAddAmountChange}
                  required
                >
                  <option value="">Select Currency</option>
                  {currencyList.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.currencyCodes}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId="formAmount" className="mt-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={addAmountForm.amount}
                  onChange={handleAddAmountChange}
                  min="0"
                  max="99999999"
                  step="0.01"
                  placeholder="Enter amount"
                  required
                  onKeyDown={(e) => {
                    // Prevent entering '+' or '-' symbols
                    if (e.key === '+' || e.key === '-') {
                      e.preventDefault();
                    }
                  }
                  }
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowAddAmountModal(false)}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Add Amount"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {/* Deduct amount model */}
      </div>
    </React.Fragment>
  );
}









// import React, { useEffect, useRef, useState } from "react";
// import Header from "../../layouts/Header";
// import TableWithPagination from "../../common/table";
// import useSkinSwitcher from "../../common/skin";
// import UserService from "../../service/api/user.service ";
// import AuthService from "../../service/api/auth.service";
// import Dropdown from "react-bootstrap/Dropdown";
// import { Link, useNavigate } from "react-router-dom";
// import "./user.css";
// import { Button, Card, Row, Col, Nav, ListGroup, Table, Badge } from "react-bootstrap";
// import PageBreadcrumb from "../../common/PageBreadcrum";

// import "cryptofont/css/cryptofont.css";
// import Sidebar from "../../layouts/Sidebar";
// import { toast } from "react-toastify";

// export default function UserList() {
//   const userService = UserService();
//   const authService = AuthService();
//   const navigate = useNavigate();
//   const { skin, setSkin } = useSkinSwitcher();
//   console.log(skin,"color mode");
//   const [staffList, setStaffList] = useState([]);
//   const [pagination, setPagination] = useState({
//     page: 1,
//     searchBy: '',
//     limit:10
//   });
//   const [columns, setColumns] = useState(["name", "username", 'email', 'phone', "status", "id"]);
  
 

//   useEffect(() => {
//     // getUserWallet();
//     authService.getLoginData()
//   }, [])
  

//   useEffect(() => {
//     getList();
   
// }, [pagination.searchBy, pagination.page]);

//   const getList = async () => {
//     const res = await userService.getUser({
//       page: pagination?.page,
//       limit: pagination?.limit,
//       searchBy: pagination?.searchBy
//     });
//     if (res?.code === 200) {
//       setStaffList(res?.data?.docs);
//       setPagination((prevPagination) => ({
//         ...prevPagination,
//         pages: res.data.pages,
//         totalDocs: res.data.totalDocs,
//         limit: res.data.limit
//       }));
//     } else {
//       console.error(res?.message);
//       // toast.error(res?.message);
//     }
//   };

//   // Define HTML for status badges
//   const getStatusBadge = (status) => {
//     if (status === 1) {
//         return <Badge  bg={'success'} className="badge badge-success">Active</Badge>;
//       } else if (status === 0) {
//         return <Badge  bg={'pink'} className="badge badge-danger">Inactive</Badge>;
//       }
//   };

//   // Define HTML for action column
//   const getActionDropdown = (id,status) => {
//     console.log("id", id);
//     return (
//       <Dropdown>
//         <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
//           <i className="ri-more-2-fill"></i>
//         </Dropdown.Toggle>

//         <Dropdown.Menu>
//           <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>
//           <Dropdown.Item onClick={() => handleWalletView(id)}>View Wallet</Dropdown.Item>
//           <Dropdown.Item onClick={() => handleBankView(id)}>View Bank</Dropdown.Item>
//           <Dropdown.Item onClick={() => handleAddAmount(id)}>Add Amount</Dropdown.Item>
//           <Dropdown.Item onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
//           <Dropdown.Item onClick={() => handleStatusUpdate(id)}>
//           {status === 1 ? "Deactivate" : "Activate"}
//           </Dropdown.Item>
//           <Dropdown.Item onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
//         </Dropdown.Menu>
//       </Dropdown>
//     );
//   };

//   const handleUpdate = (id) => {
//     const state = { id: id };
//     navigate(`/user-management/edit`, { state });
//   };

//   const handleView = (id) => {
//     const state = { id: id };
//       navigate(`/user-management/view`, { state });
    
//   };
//   const handleWalletView = (id) => {
//     const state = { id: id };
//     navigate('/user-management/view-wallet', { state });
//   }

//   const handleAddAmount = (id) => {
    
//   }

//   const handleBankView = (id) => {
//     const state = { id: id };
//     navigate('/user-management/view-bank', { state });
//   }
  

//   const handleStatusUpdate = async (id) => {
//     // const state = {id: id}
//     // navigate(`/role-management/edit`, {state})
//     const role = staffList.filter((item) => item.id === id);
//     const res = await userService.updateUser(role[0].id, {...role[0], 
//       status: role[0].status == 1 ? 0 : 1,
//     });
//     if (res?.code == 200) {
//       toast.success(res?.message);
//       getList();
//     } else {
//       toast.error(res?.message);
//     }
//   };

//   const handleDelete = async (id) => {
//     // const state = {id: id}
//     // navigate(`/role-management/edit`, {state})
//     const role = staffList.filter((item) => item.id === id);
//     const res = await userService.deleteStaff(id);
//     if (res?.code == 200) {
//       toast.success(res?.message);
//       getList();
//     } else {
//       toast.error(res?.message);
//     }
//   };

//   // Map the columns to include HTML for status badges
//   const columnsWithHTML = columns.map((column) => {
//     if (column.toLowerCase() === "status") {
//       return {
//         header: "Status",
//         accessor: column,
//         Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
//       };
//     } else if (column.toLowerCase() === "id") {
//       return {
//         header: " ",
//         accessor: column,
//         Cell: ({ value ,row}) => getActionDropdown(value,row?.status), // Render action dropdown based on the value
//       };
//     } else if (column.toLowerCase() === "name") {
//       return {
//         header: "Name",
//         accessor: column,
//         Cell: ({ value }) => {
//           // console.log("value-transaction", value, column);
//           return value || "N/A";
//         }, // Render action dropdown based on the value
//       };
//     } else if (column.toLowerCase() === "email") {
//       return {
//         header: "email",
//         accessor: column,
//         Cell: ({ value }) => {
//           // console.log("value-transaction", value, column);
//           return value || "N/A";
//         }, // Render action dropdown based on the value
//       };
//     } 
//     else if (column.toLowerCase() === "username") {
//       return {
//         header: "Username",
//         accessor: column,
//         Cell: ({ value }) => {
//           // console.log("value-transaction", value, column);
//           return value?.toUpperCase() || "N/A";
//         }, // Render action dropdown based on the value
//       };
//     } 
//     else if (column.toLowerCase() === "phone") {
//       return {
//         header: "Phone",
//         accessor: column,
//         Cell: ({ value }) => {
//           // console.log("value-transaction", value, column);
//           return value || "N/A";
//         }, // Render action dropdown based on the value
//       };
//     } 
//     else {
//       return column;
//     }
//   });

//   // const handleSearchChange = (event) => {
//   //   const value = event.target.value.trim();
//   //   if (value.length > 3) {
//   //     setPagination({ ...pagination, searchBy: value });
//   //   }
//   //   if (value.length == 0) {
//   //     setPagination({ ...pagination, searchBy: value });
//   //   }
//   // };
//   const useDebounce = (func, delay) => {
//     const debounceRef = useRef(null);
  
//     return (...args) => {
//       if (debounceRef.current) {
//         clearTimeout(debounceRef.current);
//       }
//       debounceRef.current = setTimeout(() => {
//         func(...args);
//       }, delay);
//     };
//   };
  
//   const handleSearchChange = (event) => {
//     const value = event.target.value.trim();
//     // if (value.length > 3 || value.length === 0) {
//     // }
//     setPagination((prev) => ({ ...prev, searchBy: value }));
//   };
  
//   const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);
  
//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <Sidebar />
//       <div className="main main-app p-3 p-lg-4">
//         <Card>

//         <div className=" align-items-center">
//           <Card.Header>

//               <PageBreadcrumb title="Users"  />
//             {/* <h1>User Management</h1> */}
        
//           </Card.Header>
//           <TableWithPagination
         
//             data={[...staffList]}
//             itemsPerPage={pagination?.limit}
//             columns={columnsWithHTML}
//             currentPage={pagination?.page}
//             totalPages1={pagination?.totalPages}
//             totalData={pagination?.totalDocs}
//             pagination={pagination}
//             setPagination={setPagination}
//             redirectUrl={"/staff-management/add"}
//             moduleId={3}
//             handleSearchChange={handleSearchChange}
//           />
//         </div>
//         </Card>
//       </div>
//     </React.Fragment>
//   );
// }
