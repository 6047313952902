// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const MerchantService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
  const dispatch = useDispatch();
  const axiosInstance = createAxiosInstance(bearerToken);
  const createMerchant = async (requestData) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .post(`/admin/merchant`, requestData, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getMerchants = async ({ page, limit, searchBy }) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .get(`/admin/merchant?page=${page}&limit=${limit}&searchBy=${searchBy}`, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateMerchantStatus = async (id, requestData) => {
    return await axiosInstance
      .patch(`/admin/merchant/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const updateStaffStatus = async (requestData) => {
    return await axiosInstance
      .post(`/admin/user`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const deleteStaff = async (id) => {
    return await axiosInstance
      .delete(`/admin/merchant/${id}`, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getMerchantById = async (id) => {
    return await axiosInstance
      .get(`/admin/merchant/${id}`, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };


  const getAllMerchants = async () => {
    return await axiosInstance
      .get(`/admin/merchant/list/dropdown`, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  // admin/merchant/assign-game

  const AssignGame = async (requestData) => {
    return await axiosInstance
      .post(`/admin/merchant/assign-game`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  // admin/merchant/get-assign-game/664b2c7ef6b48c577dcb6ea1

  const getAssignedGames = async (id) => {
    return await axiosInstance
      .get(`/admin/merchant/get-assign-game/${id}`)
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };


  // /transaction/merchant/manual-transaction
  const AddAmountInMerchantWallet = async (requestData) => {
    return await axiosInstance
      .post(`/transaction/merchant/manual-transaction`, requestData, { headers: {} })
      .then((response) => {
        // console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // withdraw amount

  const WithdrawAmountInMerchantWallet = async (requestData) => {
    return await axiosInstance
      .post(`/transaction/merchant/manual-payout`, requestData, { headers: {} })
      .then((response) => {
        // console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  return {
    WithdrawAmountInMerchantWallet,
    AddAmountInMerchantWallet,
    getAssignedGames,
    AssignGame,
    getAllMerchants,
    createMerchant,
    getMerchants,
    updateMerchantStatus,
    getMerchantById,
    updateStaffStatus,
    deleteStaff
  };
};

export default MerchantService;
