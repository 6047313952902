import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import StaffService from "../../service/api/staff.service";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import "./staff.css";
import Footer from "../../layouts/Footer";
import _ from 'lodash';
import PageBreadcrumb from "../../common/PageBreadcrum";
// import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Row,
  Col,
  Nav,
  ListGroup,
  Table,
  Badge,
} from "react-bootstrap";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import globalSwal from "../../common/swalService";

export default function StaffList() {
  const staffService = StaffService();
  const authService = AuthService();
  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [staffList, setStaffList] = useState([]);
  const [pagination, setPagination] = useState({
    searchBy: "",
    page: 1,
    limit: 10,
  });
  const [columns, setColumns] = useState([
    "name",
    "email",
    "phone",
    "role",
    "status",
    "id",
  ]);

  // useEffect(() => {
  //   getList();
  // }, []);



  const getList = useCallback(async () => {
    const res = await staffService.getStaff({
      page: pagination?.page,
      limit: pagination?.limit,
      searchBy: pagination?.searchBy,
    });
    if (res?.code === 200) {
      setStaffList(res?.data?.docs);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pages: res.data.pages,
        totalDocs: res.data.totalDocs,
        limit: res.data.limit
      }));
    } else {
      console.error(res?.message);
      // toast.error(res?.message);
    }
  }, [pagination.page, pagination.limit, pagination.searchBy]);
  useEffect(() => {
    getList()
    console.log("searchBy", pagination.page)
    console.log("searchBy", pagination.searchBy)
    console.log("searchBy", pagination.page)
    // authService.getLoginData();
  }, [pagination.page, pagination.searchBy, getList])
  // Define HTML for status badges
  const getStatusBadge = (status) => {
    if (status === 1) {
      return (
        <Badge bg={"success"} className="badge badge-success">
          Active
        </Badge>
      );
    } else if (status === 0) {
      return (
        <Badge bg={"pink"} className="badge badge-danger">
          Inactive
        </Badge>
      );
    }
  };

  // Define HTML for action column
  const getActionDropdown = (id, status) => {
    console.log("id", id);
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleUpdate("view", id)}>
            View
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleUpdate("edit", id)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleStatusUpdate(id)}>
            {status === 1 ? "Deactivate" : "Activate"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleUpdate = (type, id) => {
    const state = { id: id };
    if (type == "edit") {
      navigate(`/staff-management/edit`, { state });
    } else {
      navigate(`/staff-management/view`, { state });
    }
  };

  const handleStatusUpdate = async (id) => {
    // const state = {id: id}
    // navigate(`/role-management/edit`, {state})
    const role = staffList.filter((item) => item.id === id);
    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to change the current status?",
      onConfirm: async () => {

        const res = await staffService.updateStaffStatus({
          ids: [id],
          status: role[0].status == 1 ? "deactive" : "active",
        });
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Status has been changed successfully.",
          });
          toast.success(res?.message || "Status updated successfully.");
          getList();
        } else {
          toast.error(res?.message || "Failed to update status.");
        }

      },
    });


  };

  const handleDelete = async (id) => {
    // const state = {id: id}
    // navigate(`/role-management/edit`, {state})
    const role = staffList.filter((item) => item.id === id);
   
    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to delete?",
      onConfirm: async () => {


        const res = await staffService.deleteStaff(id);
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Staff has been deleted successfully.",
          });
          toast.success(res?.message);
          getList();
          setPagination({ ...pagination, page: 1 })
        } else {
          toast.error(res?.message || "Failed to delete.");
        }

      },
    });
  };

  // Map the columns to include HTML for status badges
  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "status") {
      return {
        header: "Status",
        accessor: column,
        Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
      };
    } else if (column.toLowerCase() === "id") {
      return {
        header: " ",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row?.status), // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "role") {
      return {
        header: "Role",
        accessor: column,
        Cell: ({ value }) => value?.name, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "name") {
      return {
        header: "Name",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "email") {
      return {
        header: "Email",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "phone") {
      return {
        header: "Phone",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "name") {
      return {
        header: "Name",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else {
      return column;
    }
  });


  // const handleSearchChange = (event) => {
  //   const value = event.target.value.trim();
  //   if(value.length > 3) {

  //     setPagination({...pagination, searchBy: value})
  //   }
  //   if(value.length == 0 ) {

  //     setPagination({...pagination, searchBy: value})
  //   }
  // }
  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const debouncedSearch = useCallback(_.debounce((value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      searchBy: value,
    }));
  }, 300), []);
  const handleSearchChange = (event) => {
    console.log("searchBy==")
    const value = event.target.value.trim();
    // if (value.length > 3 || value.length === 0) {
    debouncedSearch(value);
    // }
  };

  // const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>

          <div className=" align-items-center">
            <Card.Header>

              <PageBreadcrumb title="Staffs" />
              {/* <h1>Staff Management</h1> */}
              {/* <div className="d-md-flex py-3 align-items-center justify-content-between">
            <div className="form-search me-auto">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={handleSearchChange}
              />
              <i className="ri-search-line"></i>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate("/staff-management/add")}
            >
              Add
            </button>
          </div> */}
            </Card.Header>
            <TableWithPagination
              data={[...staffList]}
              itemsPerPage={pagination?.limit}
              columns={columnsWithHTML}
              currentPage={pagination?.page}
              totalPages1={pagination?.totalPages}
              totalData={pagination?.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              redirectUrl={"/staff-management/add"}
              moduleId={2}
              handleSearchChange={handleSearchChange}
            />
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}
