// src/components/Logo.js
import React from 'react';
import { useSelector } from 'react-redux';
import useSkinSwitcher from "./skin";
import darkLogo from '../assets/logo/logo-white.png'; // path to light mode logo
import lightLogo from '../assets/logo/logo-blue.png'; // path to dark mode logo

const Logo = () => {
  // const { skin } = useSkinSwitcher();
  const skin = useSelector((state) => state.ui.skin);

  return (
    <img 
      src={skin === 'dark' ? darkLogo : lightLogo} 
      alt="Logo" 
      style={{ width: '150px' }} // Adjust the width as needed
    />
  );
};

export default Logo;
