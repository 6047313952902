import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import MerchantService from "../../service/api/merchant.service";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import InfoComponent from "../../common/Info";
// const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@`~'"]+(\.[^<>()\[\]\\.,;:\s@`~'"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
// const emailRegex = new RegExp(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
const emailRegex = new RegExp(/^([a-zA-Z0-9.]+)@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);


const AddNewMerchant = () => {
  const { skin, setSkin } = useSkinSwitcher();
  const roleService = RoleService();
  const merchantService = MerchantService();

  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
    phone: "",
    supportType: "",
    supportNumber: "",
    depositeNumber: "",
    domain: "",
    adminDomain:"",
    metaDescription: "",
    metaKeywords: "",
    metaTitle: "",
    logo: null
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#141926", // Background color
      color: "#fff", // Text color
      borderColor: state.isFocused ? "#fff" : "#444", // Border color
      boxShadow: state.isFocused ? "0 0 0 1px #fff" : "none", // Box shadow
      "&:hover": {
        borderColor: "#141926", // Border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#555"
        : state.isFocused
          ? "#666"
          : "#141926", // Option background color
      color: "#fff", // Option text color
      "&:hover": {
        backgroundColor: "#555", // Option background color on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333", // Menu background color
      color: "#fff", // Menu text color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Single value text color
    }),
  };

  useEffect(() => {
    getRoleList();
  }, []);

  const getRoleList = async () => {
    const res = await roleService.getRolelistDropdown();
    if (res?.code === 200) {
      setRoleList(res.data);
      let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      setRoleOption(data);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;


    setFormData({ ...formData, [name]: value });
    // showError(false)
    setErrors({ ...errors, [name]: "" });
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setErrors((prev) => ({
          ...prev,
          logo: "Only JPG and PNG files are allowed.",
        }));
        return;
      }
      if (file.size > 100 * 1024) {
        setErrors((prev) => ({
          ...prev,
          logo: "Logo size must be less than 100KB.",
        }));
        return;
      }
  
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          if (img.width !== 280 || img.height !== 76) {
            setErrors((prev) => ({
              ...prev,
              logo: "Logo dimensions must be 280x76 pixels.",
            }));
          } else {
            setErrors((prev) => ({ ...prev, logo: "" }));
            setFormData((prev) => ({ ...prev, logo: file }));
            setImagePreview(reader.result); // Set preview only if dimensions are valid
          }
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your form submission logic here
    // Perform validations

    try {
      setIsSubmitting(true);
      console.log("formData", formData);
      const validationErrors = {};

      if (formData.name?.trim() == "") {
        validationErrors.name = "Name is required";
      } else if (!/^(?!\d+$)[a-zA-Z0-9\s]+$/.test(formData.name)) {
        validationErrors.name = "Name should be alphanumeric";
      } else if (formData.name?.length > 50) {
        validationErrors.name = "Name must not contain more than 50 characters";
      }
      else {
        validationErrors.name = "";
      }


      if (formData.depositeNumber?.trim() == "") {
        validationErrors.depositeNumber = "Deposit mobile number is required";
      } else if (!/^\d{6,15}$/.test(formData.depositeNumber?.trim())) {
        validationErrors.depositeNumber = "Deposit mobile number should be in between 6 to 15 digits";
      }
      else if (!/^[2-9]/.test(formData.depositeNumber?.trim())) {
        validationErrors.depositeNumber = "Please enter a valid deposit mobile number"
      }
      else {
        validationErrors.depositeNumber = "";
      }

      if (formData.phone?.trim() == "") {
        validationErrors.phone = "Phone number is required";
      } else if (!/^\d{6,15}$/.test(formData.phone?.trim())) {
        validationErrors.phone = "Phone number should be in between 6 to 15 digits";
      }
      else if (!/^[2-9]/.test(formData.phone?.trim())) {
        validationErrors.phone = "Please enter a valid phone number"
      }
      else {
        validationErrors.phone = "";
      }
      if (formData.supportType?.trim() == "") {
        validationErrors.supportType = "Support type is required";
      }
      else {
        validationErrors.supportType = "";

      }

      if (formData.metaTitle?.trim() == "") {
        validationErrors.metaTitle = "Meta title is required";
      }
      else {
        validationErrors.metaTitle = "";

      }

      if (!formData.logo) {
        validationErrors.logo = "Logo is required.";
      }

      if (formData.supportNumber?.trim() == "") {
        validationErrors.supportNumber = "Support mobile number is required";
      } else if (!/^\d{6,15}$/.test(formData.supportNumber?.trim())) {
        validationErrors.supportNumber = "Support mobile number should be in between 6 to 15 digits";
      }
      else if (!/^[2-9]/.test(formData.supportNumber?.trim())) {
        validationErrors.supportNumber = "Please enter a valid support mobile number"
      }
      else {
        validationErrors.supportNumber = "";
      }

      if (formData.email?.trim() == "") {
        validationErrors.email = "Email is required";
      } else if (!emailRegex.test(formData.email)) {
        validationErrors.email = "Invalid email address";
      } else {
        validationErrors.email = "";
      }

      if (formData.password?.trim() == "") {
        validationErrors.password = "Password is required";
      } else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(
          formData.password
        )
      ) {
        validationErrors.password =
          "Password must be at least 8 characters long and contain at least one letter and one number including special character.";
      } else {
        validationErrors.password = "";
      }

      if (formData.confirmPassword?.trim() == "") {
        validationErrors.confirmPassword = "Confirm password is required";
      }
      else if (formData.confirmPassword !== formData.password) {
        validationErrors.confirmPassword = "Passwords do not match.";

      }
      else {
        validationErrors.confirmPassword = "";
      }

      if (!formData.domain?.trim()) {
        validationErrors.domain = "User domain is required";
      } else {
        const originDomainRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:\d+)?$/;
        if (!originDomainRegex.test(formData.domain.trim())) {
          validationErrors.domain = "Please enter a valid domain (e.g., https://example.com)";
        }
        else {
          validationErrors.domain = "";
        }
      }

      // adminDomain

      if (!formData.adminDomain?.trim()) {
        validationErrors.adminDomain = "Merchant domain is required";
      } else {
        const originDomainRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:\d+)?$/;
        if (!originDomainRegex.test(formData.adminDomain.trim())) {
          validationErrors.adminDomain = "Please enter a valid merchant domain (e.g., https://example.com)";
        }
        else {
          validationErrors.adminDomain = "";
        }
      }



      // Add more password validation logic as needed
      console.log("validationErrors", Object.values(validationErrors));
      if (errors.logo) {
        return;
      }
      if (Object.values(validationErrors).every((item) => item === "")) {
        // No errors, proceed with form submission
        // console.log("Form submission",formData)
        const formDataToSend = new FormData();
        // Loop through the formData keys and append them to formDataToSend
        Object.keys(formData).forEach((key) => {
          if (key !== 'confirmPassword') {
            formDataToSend.append(key, formData[key]);
            
          }
        });
      
        setShowError(false);
       
      
        const res = await merchantService.createMerchant(formDataToSend);
      
        if (res?.code == 200) {
          toast.success(res?.message);
          navigate("/merchant-management");
        } else {
          toast.error(res?.message);
        }
      } else {
        // Set validation errors
        setShowError(true);
        setErrors(validationErrors);
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRoleChange = (selectedOption) => {
    let validationErrors = "";
    if (selectedOption.value == "") {
      validationErrors = "Role is required";
    } else {
      validationErrors = "";
    }
    setShowError(true);
    setErrors({ ...errors, role: validationErrors });
    setFormData({ ...formData, role: selectedOption.value });
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className=" align-items-center mb-4">
          <Card>
            <Card.Header>
              <h4 className="card-title w-100">Add Merchant</h4>
            </Card.Header>
            <Card.Body>
              <Form className="row" onSubmit={handleSubmit}>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* depositeNumber: String,
                supportNumber: String, */}
                <Col md={4} className="mt-3">
                  <Form.Group controlId="phone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="role">
                    <Form.Label>Support Type</Form.Label>


                    <Form.Select
                      name="supportType"
                      onChange={handleChange}
                      value={formData.supportType}
                      placeholder="Select support type"

                      isInvalid={showError && !!errors.supportType}
                    >
                      {/* ["whatsapp", "telegram", "intercom"] */}
                      <option value="">Select Type</option>
                      <option value="whatsapp">whatsapp</option>
                      <option value="telegram">Telegram</option>
                      {/* <option value="intercom">Intercom</option> */}
                      <option value="kripabetintercom">Kripabet Intercom</option>

                    </Form.Select>
                    <Form.Text className="text-danger small">
                      {errors.supportType !== "" && showError && "Support type is required"}
                    </Form.Text>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="supportNumber">
                    <Form.Label>Support Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter support number"
                      name="supportNumber"
                      value={formData.supportNumber}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.supportNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.supportNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="depositeNumber">
                    <Form.Label>Deposit Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter deposit number"
                      name="depositeNumber"
                      value={formData.depositeNumber}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.depositeNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.depositeNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={showPassword.password ? "text" : "password"}
                        placeholder="Enter password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        isInvalid={showError && !!errors.password}

                      />
                      <span
                        onClick={() => togglePasswordVisibility("password")}
                        style={{
                          position: "absolute",
                          top: "18px",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#6c757d",
                          zIndex: 100
                        }}
                      >
                        {showPassword.password ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                      </span>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={showPassword.confirmPassword ? "text" : "password"}
                        placeholder="Enter Confirm Password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        isInvalid={showError && !!errors.confirmPassword}
                      />
                      <span
                        onClick={() => togglePasswordVisibility("confirmPassword")}
                        style={{
                          position: "absolute",
                          top: "18px",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#6c757d",
                          zIndex: 10000
                        }}
                      >
                        {showPassword.confirmPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                      </span>

                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="domain">
                    <Form.Label>User Domain</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter User Domain"
                      name="domain"
                      value={formData.domain}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.domain}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.domain}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="adminDomain">
                    <Form.Label>Merchant Domain</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Merchant Domain"
                      name="adminDomain"
                      value={formData.adminDomain}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.adminDomain}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.adminDomain}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="metaTitle">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Meta Title"
                      name="metaTitle"
                      value={formData.metaTitle}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.metaTitle}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.metaTitle}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="metaKeywords">
                    <Form.Label>Meta Keywords</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Meta Keywords"
                      name="metaKeywords"
                      value={formData.metaKeywords}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.metaKeywords}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.metaKeywords}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="metaDescription">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      type="text"
                      as={'textarea'}
                      rows={4}
                      placeholder="Enter Description "
                      name="metaDescription"
                      value={formData.metaDescription}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.metaDescription}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.metaDescription}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group>
                    <Form.Label>Logo</Form.Label>
                    <div className="image-upload-wrapper">
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className="img-thumbnail"
                          style={{ width: "100px", height: "100px" }}
                        />
                      ) : (
                        <div className="image-placeholder">
                          Logo Not Available
                          <InfoComponent message={"Max file size should be 100kb and dimensions must be 280x76 pixels."} />
                        </div>
                      )}
                      <Form.Control
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={handleImageChange}
                        isInvalid={!!errors.logo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.logo}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>

                <div className="d-flex justify-content-center mt-5">
                  <Button disabled={isSubmitting} variant="primary" type="submit" className="mx-4">
                    {isSubmitting ? 'Please wait...' : 'Submit'}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="danger"
                    type="button"
                    onClick={() => navigate("/merchant-management")}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddNewMerchant;
