
import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const GameSettingService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
  const dispatch = useDispatch();
  const axiosInstance = createAxiosInstance(bearerToken);
  const createGameSetting = async (requestData) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .post(`/admin/game-setting`, requestData, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        // console.log("created =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Creation failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getGameSetting = async ({ page, limit, searchBy }) => {
    dispatch(startApiRequest());
    return await axiosInstance
      // /admin/game-setting?page=1&limit=10&searchBy=
      .get(`/admin/game-setting?page=${page}&limit=${limit}&searchBy=${searchBy}`, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        // console.log(" =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateGameSetting = async (id, requestData) => {
    return await axiosInstance
      // admin/game-setting/667d505a16490b9f26f75ded
      .patch(`/admin/game-setting/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("updated =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Updation failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // /admin/game/list/dropdown
  const deleteGame = async (id) => {
    return await axiosInstance
      .delete(`/admin/game/${id}`, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const getGameListDropdown = async (id) => {
    return await axiosInstance
      .get(`/admin/game/list/dropdown`, { headers: {} })
      .then((response) => {
        // console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getGameSettingById = async (id) => {
    return await axiosInstance
      // admin/game-setting/667d505a16490b9f26f75ded
      .get(`/admin/game-setting/${id}`, { headers: {} })
      .then((response) => {
        // console.log("F =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };


  return {
    createGameSetting,
    getGameSettingById,
    updateGameSetting,
    getGameSetting,
    getGameListDropdown
    // deleteGame
  };
};

export default GameSettingService;
