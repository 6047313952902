import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import { darkModeStyles, lightModeStyles } from "../../common/reactSelectStyles";
import CurrencyService from "../../service/api/currency.service";
import MerchantService from "../../service/api/merchant.service";
import { toast } from "react-toastify";
import GameService from "../../service/api/game.service";
import { BorderSpinner } from "../../common/Spinner";
import Select from "react-select"; // For multiselect input

const AssignGames = ({ show, onClose, merchantId }) => {
    const merchantService = MerchantService();
    const theme = useSelector((state) => state.ui.skin);
    const [isAssigned, setIsAssigned] = useState(false);
    const gameService = GameService();
    const [gameCategories, setGameCategories] = useState([]); // Loaded dynamically
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedGames, setSelectedGames] = useState([]);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [selectedGamesAndCategory, setSelectedGamesAndCategory] = useState(null);
    const [assignedGames, setAssignedGames] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [isGameLoading, setIsGameLoading] = useState(true);



    useEffect(() => {


        // getGameList();
        if (show) {
            getAssignedGames(merchantId);
        }
        if (!show) {
            setSelectedCategories([]);
            setSelectedGames([]);
            setIsGameLoading(true);
        }
    }, [show])

    useEffect(() => {
        getGameCategoryList();
    }, []);
    const validateInputs = () => {
        if (!selectedCategories.length) {
            // toast.error("Please select at least one category.");
            return false;
        }
        if (!selectedGames.length) {
            // toast.error("Please select at least one game.");
            return false;
        }
        return true;
    };

    const getAssignedGames = async (id) => {
        try {
            const res = await merchantService.getAssignedGames(id);
            if (res?.code === 200) {
                // Extract the games data from the response
                const responseGames = res?.data[0]?.games || [];

                // Group games by category with corresponding format
                const categoryOptions = responseGames.reduce((acc, item) => {
                    const categoryId = item.categoryId.id;
                    const categoryName = item.categoryId.name;

                    // Check if this category already exists in the accumulator
                    let category = acc.find(cat => cat.value === categoryId);
                    if (!category) {
                        // Create a new category object
                        category = {
                            label: categoryName,
                            value: categoryId,
                            games: [],
                        };
                        acc.push(category);
                    }

                    // Add the game to the category's games array
                    category.games.push({
                        label: item.gameId.name,
                        value: item.gameId.id,
                        category: categoryId,
                    });

                    return acc;
                }, []);

                // Flatten games for the selected games list
                const games = categoryOptions.flatMap(cat => cat.games);

                // Update state with categories and games
                setSelectedGamesAndCategory({
                    games: games,
                    category: categoryOptions,
                });


                console.log(games, categoryOptions, "selected games and category");
                // Update selected categories with matching category list data
                setSelectedCategories((prevValue) =>
                    categoryOptions.map((category) => {
                        // Find matching category in categoryList
                        const matchingCategory = categoryList.find(
                            (categoryItem) => categoryItem.value === category.value
                        );

                        return {
                            ...category,
                            games: matchingCategory?.games || category.games, // Retain games if no match is found
                        };
                    })
                );

                // Sync games to state
                setSelectedGames(games);
            } else {
                console.error(res?.message || "Failed to retrieve games.");
            }
        } catch (error) {
            console.error("Error fetching assigned games:", error);
        } finally {
            setIsGameLoading(false);
        }
    };


    const getGameCategoryList = async () => {
        const res = await gameService.getCategoryList();
        if (res?.code === 200) {
            const categoryOptions = res?.data
                .filter(item => item.status === 1 && item.isDelete === 1) // Ensure only active and non-deleted categories
                .map(category => ({
                    label: category.name, // Use the `name` as label
                    value: category.id,   // Use the `id` as value
                    games: category.games
                        .filter(game => game.status === 1 && game.isDelete === 1) // Filter active and non-deleted games
                        .map(game => ({
                            label: game.name,  // Use the `name` as label
                            value: game.id,    // Use the `id` as value
                            category: game.category, // Use the `id` as category
                        }))
                }));

            setCategoryList(categoryOptions);
            // Sync categories

        } else {
            console.error(res?.message);
            // toast.error(res?.message);
        }
    };

    const assignGamesToMerchant = async () => {
        try {
            setIsAssigned(true);
            const payload = {
                merchantId: merchantId,
                games: selectedGames.map(game => ({
                    gameId: game.value,
                    categoryId: game.category
                }))
            };


            const res = await merchantService.AssignGame(payload);
            if (res?.code === 200) {
                toast.success("Games assigned successfully!");
                onClose();
                // getList(); // Refresh the merchant list
            } else {
                throw new Error(res?.message || "Failed to assign games.");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsAssigned(false);
        }
    };

    const handleCategoryChange = (selectedOptions) => {
        setSelectedCategories(selectedOptions || []);
        console.log(selectedOptions,selectedGames,"option and games")
        // Filter out games associated with removed categories
        setSelectedGames((prevSelectedGames) =>
            prevSelectedGames.filter((game) =>
                selectedOptions.some((category) => category.value === game.category)
            )
        );
        setSelectedGamesAndCategory((prev) => ({
            ...prev,
            category: selectedOptions || [],
            games: [],
        }));
    };

    const handleGameChange = (selectedOptions) => {
        setSelectedGames(selectedOptions || []);
        setSelectedGamesAndCategory((prev) => ({
            ...prev,
            games: selectedOptions || [],
        }));
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Assign Games to Merchant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isGameLoading ? <>
                    <div className="d-flex mt-5 justify-content-center align-content-center">
                        <BorderSpinner />
                    </div>
                </> : (<>
                    <div className="mb-3">
                        <label>Game Categories</label>
                        <Select
                            options={categoryList}
                            isMulti
                            value={selectedCategories}
                            styles={theme === 'dark' ? darkModeStyles : lightModeStyles}
                            placeholder={"Select catagories..."}

                            onChange={handleCategoryChange}
                        />
                        {!selectedCategories.length && (
                            <small className="text-danger">Please select at least one category.</small>
                        )}
                    </div>
                    <div className="mb-3">
                        <label>Games</label>
                        <Select
                            styles={theme === 'dark' ? darkModeStyles : lightModeStyles}
                            placeholder={"Select games..."}
                            options={selectedCategories.flatMap((cat) => cat.games)}
                            isMulti
                            value={selectedGames}
                            onChange={handleGameChange}
                        />
                        {!selectedGames.length && (
                            <small className="text-danger">Please select at least one game.</small>
                        )}
                    </div>
                </>)}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isAssigned} variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        if (validateInputs()) {
                            assignGamesToMerchant();
                        }
                    }}
                    disabled={isAssigned || !validateInputs()}

                >
                    {!isAssigned ? 'Assign' : 'Pleas Wait...'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AssignGames