import { useDispatch } from "react-redux";
import createAxiosInstance from "../api.instance";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";

const DashboardService = () => {
    const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
    const BASIC_AUTH = 'Basic a3JpcGFiZXQ6a3JpcGFiZXRAMTIzNDU=';
    const axiosInstanceBasic = createAxiosInstance(BASIC_AUTH, "basic");
    const axiosInstance = createAxiosInstance(bearerToken);

    const dispatch = useDispatch();
    const getBetStatistics = async (params) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .get(
                `/dashboard/bet-analysis`, { params }
            )
            .then((response) => {
                dispatch(endApiRequest());

                // console.log("Static Data =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                dispatch(endApiRequest());
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    // dashboard/transaction-analysis?startDate="2024-01-01"&endDate="2024-08-31"
    const getBetTransactionMetrics = async (params) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .get(
                `/dashboard/transaction-analysis`, { params }
            )
            .then((response) => {
                dispatch(endApiRequest());

                // console.log("Static Data =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                dispatch(endApiRequest());
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    const getGameMetrics = async (params) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .get(
                `/dashboard/game-analysis`, { params }
            )
            .then((response) => {
                dispatch(endApiRequest());

                // console.log("Static Data =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                dispatch(endApiRequest());
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    // dashboard/user-analysis?startDate="2024-08-01"&endDate="2024-08-31"
    const getBetUserMetrics = async (params) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .get(
                `/dashboard/user-analysis`, { params }
            )
            .then((response) => {
                dispatch(endApiRequest());

                // console.log("Static Data =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                dispatch(endApiRequest());
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    const getLotteryDashboardData = async (params) => {
        return await axiosInstance
            .get(`/dashboard/lottery-analysis`,{ params })
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    }

    // dashboard/lottery-graph-analysis?startDate="2024-11-01"&endDate="2024-11-31"

    const getLotteryGraphData = async (params) => {
        return await axiosInstance
            .get(`/dashboard/lottery-graph-analysis`,{ params })
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    }
    return {
        getLotteryGraphData,
        getLotteryDashboardData,
        getBetStatistics,
        getBetTransactionMetrics,
        getBetUserMetrics,
        getGameMetrics
    };
};
export default DashboardService;

