import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import ApexCharts from 'react-apexcharts';
import { Activity, Timer, Users, DollarSign, Award, TrendingUp, Percent, IndianRupee } from 'lucide-react';
import { formatCurrency, getCurrencyFromTO } from '../../helper/CurrencyFormate';


const calculateTotalBetInINR = async (totalBetAmount=[]) => {
  let totalBetInINR = 0;

  for (let bet of totalBetAmount) {
    if (bet.currency === "INR") {
      totalBetInINR += bet.totalBetAmount; // If currency is INR, just add the amount
    } else {
      const convertedAmount = await getCurrencyFromTO(bet?.currency, "INR", bet?.totalBetAmount ||0);
      totalBetInINR += convertedAmount;
    }
  }

  return totalBetInINR;
};

const calculateAvgTicketPriceInINR = async (avgTicketPrice=[]) => {
  let totalConvertedPrice = 0;
  let totalCurrencies = 0;

  for (let price of avgTicketPrice) {
    if (price.currency === "INR") {
      totalConvertedPrice += price.avgTicketPrice;
    } else {
      const convertedPrice = await getCurrencyFromTO(price.currency, "INR", price?.avgTicketPrice ||0);
      totalConvertedPrice += convertedPrice;
    }
    totalCurrencies += 1;
  }

  return totalConvertedPrice / totalCurrencies;
};
const LotteryStats = ({ skin, data }) => {
  const [totalBetInINR, setTotalBetInINR] = useState(0);
  const [avgTicketPriceInINR, setAvgTicketPriceInINR] = useState(0);
 
  const axisColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : 'rgb(224, 224, 224)';
  const labelColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : '#41505f';
  const mockData = {
    stats: {
      activeLotteries: 5,
      expiredLotteries: 12,
      totalBets: 1458,
      totalBetAmount: 45780,
      averageTicketPrice: 25,
      winningRate: 12.5,
      participantsCount: 890,
      totalPrizePool: 38000
    },
    trendData: [
      { date: '2024-01', bets: 250, amount: 6250, participants: 150 },
      { date: '2024-02', bets: 310, amount: 750, participants: 80 },
      { date: '2024-03', bets: 80, amount: 12000, participants: 280 },
      { date: '2024-04', bets: 420, amount: 10500, participants: 40 }
    ]
  };


  

  useEffect(() => {
    const calculateStats = async () => {
      // const totalBet = await calculateTotalBetInINR(data?.totalBetAmount);
      // const avgTicketPrice = await calculateAvgTicketPriceInINR(data?.avgTicketPrice);
      
      // setTotalBetInINR(totalBet);
      // setAvgTicketPriceInINR(avgTicketPrice);
      setTotalBetInINR(data?.totalBetAmount || 0);
      setAvgTicketPriceInINR(data?.avgTicketPrice || 0)
    };

    calculateStats();
  }, [data]);
  


  const trendChartOptions = {

    chart: {
      type: 'line',
      stacked: false,
      height: 350,
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },

      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: 'Trend Chart Data', // Custom filename for CSV export
            columnDelimiter: ',', // Customize CSV column delimiter if needed
            headerCategory: 'Month', // Custom header name for category (X-axis)
            dateFormatter(timestamp) {
              return new Date(timestamp).toLocaleString();
            },
          },
          svg: {
            filename: 'Trend Chart Data',
            width: 800,
            height: 600,
            backgroundColor: '#000000',
          },
          png: {
            filename: 'Trend Chart Data',
            width: 800,
            height: 600,
            backgroundColor: '#000000',
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      // type: 'gradient',
      // gradient: {
      //   shadeIntensity: 1,
      //   inverseColors: false,
      //   opacityFrom: 0.5,
      //   opacityTo: 0,
      //   stops: [0, 90, 100]
      // },
    },
    xaxis: {
      categories: mockData.trendData.map(data => data.date),
      axisBorder: {
        show: true,
        color: axisColor,  // Conditional color for dark/light theme
      },
      axisTicks: {
        show: true,
        color: axisColor,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },

    },
    // yaxis: [
    //   {
    //     title: { text: 'Total Bets', style: { color: labelColor } },
    //     axisBorder: {
    //       show: true,
    //       color: axisColor,  // Conditional color for dark/light theme
    //     },
    //     labels: { style: { colors: '#2563eb' } }
    //   },
    //   { opposite: true, title: { text: 'Bet Amount', style: { color: labelColor } }, labels: { style: { colors: '#16a34a' } } },
    //   { opposite: true, title: { text: 'Participants', style: { color: labelColor } }, labels: { style: { colors: '#9333ea' } } }

    // ],

    yaxis: [
      {
        seriesName: 'Total Bets',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#008FFB',
          }
        },
        title: {
          text: "Total Bets",
          style: {
            color: '#008FFB',
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        seriesName: 'Bet Amount',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#00E396'
        },
        labels: {
          style: {
            colors: '#00E396',
          }
        },
        title: {
          text: "Bet Amount",
          style: {
            color: '#00E396',
          }
        },
      },
      {
        seriesName: 'Participants',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FEB019'
        },
        labels: {
          style: {
            colors: '#FEB019',
          },
        },
        title: {
          text: "Participants",
          style: {
            color: '#FEB019',
          }
        }
      },
    ],
 
    tooltip: {
      theme: skin === 'dark' ? 'dark' : 'light',
      y: {
        formatter: (val) => val.toFixed(0),
      },
    },
    grid: {
      axisBorder: axisColor,  // Conditional color for dark/light them,
      xaxis: {
        lines: { show: false },
      },
      yaxis: {
        lines: { show: false },
      },
      padding: {
        left: 13,
        right: 10,
        top: 0,
        bottom: 0
    }
    },
  };

  const trendChartSeries = [
    {
      name: 'Total Bets',
      type: 'line',
      data: mockData.trendData.map(data => data.bets)
    },
    {
      name: 'Bet Amount',
      type: 'line',

      data: mockData.trendData.map(data => data.amount)
    },
    {
      name: 'Participants',
      type: 'line',

      data: mockData.trendData.map(data => data.participants)
    }
  ];

  return (
    <div className="py-4">
      {/* <h1 className="text-2xl font-bold mb-6">Lottery Statistics Dashboard</h1> */}

      {/* Key Metrics Grid */}
      <Row className="mb-4">
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Active Lotteries</Card.Text>
              <Card.Title>{data?.activeLotteriesCount || 0}</Card.Title>
              <Activity className="h-8 w-8 text-green-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Expired Lotteries</Card.Text>
              <Card.Title>{data?.expiredLotteriesCount || 0}</Card.Title>
              <Timer className="h-8 w-8 text-red-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Total Bets</Card.Text>
              <Card.Title>{data?.totalBets || 0}</Card.Title>
              <Users className="h-8 w-8 text-blue-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Total Bets Amount</Card.Text>
              <Card.Title>{formatCurrency(totalBetInINR,"INR") }</Card.Title>
              {/* <DollarSign className="h-8 w-8 text-yellow-500" /> */}
              <IndianRupee className="h-8 w-8 text-yellow-500" />

            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Additional Statistics */}
      <Row className="mb-4">
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Avg Ticket Price</Card.Text>
              <Card.Title>{formatCurrency(avgTicketPriceInINR,'INR') }</Card.Title>
              <Award className="h-8 w-8 text-purple-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Winning Rate</Card.Text>
              <Card.Title>{Number(data?.winningRate || 0)?.toFixed(2) || 0}%</Card.Title>
              <Percent className="h-8 w-8 text-indigo-500" />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Total Participants</Card.Text>
              <Card.Title>{data?.totalParticipants || 0}</Card.Title>
              <Users className="h-8 w-8 text-teal-500" />
            </Card.Body>
          </Card>
        </Col>
        {/* <Col md={3}>
          <Card className="text-center p-3">
            <Card.Body>
              <Card.Text>Total Prize Pool</Card.Text>
              <Card.Title>{formatCurrency(data?.totalPrizePool || 0,'INR') }</Card.Title>
              <TrendingUp className="h-8 w-8 text-orange-500" />
            </Card.Body>
          </Card>
        </Col> */}
      </Row>

      {/* Trends Chart */}
      {/* <Card className="mt-4">
        <Card.Body>
          <Card.Title>Lottery Trends</Card.Title>
          <div className="h-[300px]">
            <ApexCharts
              options={trendChartOptions}
              series={trendChartSeries}
              type="line"
              height={300}
            />
          </div>
        </Card.Body>
      </Card> */}
    </div>
  );
};


export default LotteryStats