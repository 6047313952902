import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import { darkModeStyles, lightModeStyles } from "../../common/reactSelectStyles";
import CurrencyService from "../../service/api/currency.service";
import MerchantService from "../../service/api/merchant.service";
import { toast } from "react-toastify";

const AddAmountModal = ({ show, onClose, merchantId }) => {
  const theme = useSelector((state) => state.ui.skin);
  const currencyService = CurrencyService();
  const merchantService = MerchantService();
    const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [formData, setFormData] = useState({
    currencyId: "",
    amount: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!show) {
      // Reset the form when the modal closes
      setFormData({ currencyId: "", amount: "" });
      setValidationErrors({});
      setSelectedCurrency([]);
    }
  }, [show]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      const response = await currencyService.getCurrencyList({
        page,
        limit: 10,
        searchBy: search,
      });

      if (response.code === 200) {
        return {
          options: response.data?.docs.map((currency) => ({
            value: currency.id,
            label: `${currency.currencyCodes}`,
          })),
          hasMore: response.data.hasNextPage,
          additional: { page: page + 1 },
        };
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error fetching currency list:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.currencyId) {
      errors.currencyId = "Please select a currency.";
    }
    if (!formData.amount || isNaN(formData.amount) || Number(formData.amount) <= 0) {
      errors.amount = "Please enter a valid amount greater than 0.";
    }
    return errors;
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));

    // Clear the validation error for the field being updated
    setValidationErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setIsSubmitting(true);
    try {
     const res= await merchantService.AddAmountInMerchantWallet({
        currencyId: formData.currencyId,
        amount: Number(formData.amount), // Ensure amount is a number
        merchantId,
     });
      
      if (res.code == 200) {
        toast.success(res.message);
        setIsSubmitting(false);
        onClose(); // Close the modal
        setFormData({ currencyId: "", amount: "" });
      }
      else {
        toast.error(res.message);
        
      }

    } catch (error) {
      console.error("Error adding amount:", error);
      
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Amount to Merchant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Currency Select */}
          <Form.Group className="mb-3">
            <Form.Label>Select Currency</Form.Label>
            <AsyncPaginate
              value={selectedCurrency}
              loadOptions={loadOptions}
                          onChange={(selectedOption) => {
                            setSelectedCurrency(selectedOption)
                            handleInputChange("currencyId", selectedOption ? selectedOption.value : "")
              }
              }
              additional={{ page: 1 }}
              styles={theme === "dark" ? darkModeStyles : lightModeStyles}
              placeholder="Select a currency"
              isClearable
            />
            {validationErrors.currencyId && (
              <Form.Text className="text-danger">{validationErrors.currencyId}</Form.Text>
            )}
          </Form.Group>

          {/* Amount Input */}
          <Form.Group className="mb-3">
            <Form.Label>Enter Amount</Form.Label>
            <Form.Control
              type="number"
              min={0}
              placeholder="Enter amount"
              value={formData.amount}
              onChange={(e) => handleInputChange("amount", e.target.value)}
              isInvalid={!!validationErrors.amount}
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.amount}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? "Please Wait..." : "Add Amount"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAmountModal;
