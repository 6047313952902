import React, { useState, useEffect } from "react";
import { useCountries } from "use-react-countries";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import StaffService from "../../service/api/staff.service";
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";
// const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@`~'"]+(\.[^<>()\[\]\\.,;:\s@`~'"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
// const emailRegex = new RegExp(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
const emailRegex = new RegExp(/^([a-zA-Z0-9.]+)@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);


const AddNewSatff = () => {
  const theme = useSelector((state) => state.ui.skin);
  const { countries } = useCountries();
  const [country, setCountry] = useState(221);
  const { name, flags, countryCallingCode } = countries[country];
  // const 
  const { skin, setSkin } = useSkinSwitcher();
  const roleService = RoleService();
  const staffService = StaffService();

  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({
    value: countryCallingCode,
    label: (
      <div className="flex items-center gap-2">
        <img src={flags.svg} alt={name} className="h-5 w-5 rounded-full object-cover" />
        <span>{name}</span>
      </div>
    ),
  });
  // setSelectedCountry({
  //   value: selectedCountry.countryCallingCode,
  //   label: (
  //     <div className="flex items-center gap-2">
  //       <img src={selectedCountry.flags.svg} alt={selectedCountry.name} className="h-5 w-5 rounded-full object-cover" />
  //       <span>{selectedCountry.name}</span>
  //     </div>
  //   ),
  // });
  const [formData, setFormData] = useState({
    role: "",
    name: "",
    phone: "",
    email: "",
    password: "",
  });
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#141926", // Background color
      color: "#fff", // Text color
      borderColor: state.isFocused ? "#fff" : "#444", // Border color
      boxShadow: state.isFocused ? "0 0 0 1px #fff" : "none", // Box shadow
      "&:hover": {
        borderColor: "#141926", // Border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#555"
        : state.isFocused
          ? "#666"
          : "#141926", // Option background color
      color: "#fff", // Option text color
      "&:hover": {
        backgroundColor: "#555", // Option background color on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333", // Menu background color
      color: "#fff", // Menu text color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Single value text color
    }),
  };

  useEffect(() => {
    getRoleList();
  }, []);

  const getRoleList = async () => {
    const res = await roleService.getRolelistDropdown();
    if (res?.code === 200) {
      setRoleList(res.data);
      let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      setRoleOption(data);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("{ ...errors, [name]: errorMessage }", name, value);

    let errorMessage = "";

    // Perform validation based on the field name
    switch (name) {
      case "name":
        if (!value?.trim()) {
          errorMessage = "Name is required.";
        } else if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
          errorMessage = "Name is required";
        } else if (value?.trim().length > 50) {
          errorMessage = "Name must not contain more than 50 characters"
        }
        else {
          errorMessage = "";
        }
        break;
      case "email":
        if (!value.trim()) {
          errorMessage = "Email is required.";
        } else if (!emailRegex.test(value)) {
          errorMessage = "Please enter a valid email address";
        } else {
          errorMessage = "";
        }
        break;
      case "pasword":
        if (!value.trim()) {
          errorMessage = "Password is required.";
        } else if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(
            formData.password
          )
        ) {
          errorMessage =
            "Password must be at least 8 characters long and contain at least one letter and one number including special character.";
        } else {
          errorMessage = "";
        }
        break;
      case "phone":
        if (!value.trim()) {
          errorMessage = "Phone number is required.";
        } else if (!/^\d{6,15}$/.test(value)) {
          errorMessage = "Phone number should be in between 6 to 15 digits";
        }
        else if (!/^[2-9]/.test(value)) {
          errorMessage="Please enter a valid phone number"
          }
        else {
          errorMessage = "";
        }
        break;
      // Add validation for other fields as needed
      default:
        break;
    }


    setFormData({ ...formData, [name]: value });
    // showError(false)
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your form submission logic here
    // Perform validations
    console.log("formData", formData);
    const validationErrors = {};
    if (formData.role == "") {
      validationErrors.role = "Role is required";
    } else {
      validationErrors.role = "";
    }
    if (formData.name?.trim() == "") {
      validationErrors.name = "Name is required";
    } else if (!/^[a-zA-Z0-9\s]*$/.test(formData.name)) {
      validationErrors.name = "Name should be alphanumeric";
    } else if (formData.name?.trim().length > 50) {
      validationErrors.name = "Name must not contain more than 50 characters"
    }
    else {
      validationErrors.name = "";
    }
    if (formData.phone?.trim() == "") {
      validationErrors.phone = "Phone number is required";
    } else if (!/^\d{6,15}$/.test(formData.phone)) {
      validationErrors.phone = "Phone number should be in between 6 to 15 digits";
    }
    else if (!/^[2-9]/.test(formData.phone)) {
      validationErrors.phone = "Enter a valid phone number";
     }
    else {
      validationErrors.phone = "";
    }

    if (formData.email == "") {
      validationErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      validationErrors.email = "Please enter a valid email address";
    } else {
      validationErrors.email = "";
    }



    if (formData.password?.trim() == "") {
      validationErrors.password = "Password is required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(
        formData.password
      )
    ) {
      validationErrors.password =
        "Password must be at least 8 characters long and contain at least one letter and one number including special character.";
    } else {
      validationErrors.password = "";
    }

    if (!selectedCountry) {
      console.log("Please select a country")
      validationErrors.countryCode = "Country code is required.";
    } else {
      console.log("countryselected: " + selectedCountry)
      validationErrors.countryCode = "";
    }

    // Add more password validation logic as needed
    console.log("validationErrors", Object.values(validationErrors));

    if (Object.values(validationErrors).every(item => item === '')) {
      // No errors, proceed with form submission
      console.log("resss===")
      setShowError(false);
      console.log("formData", formData);
      const playload = {
        ...formData,
        phone:selectedCountry.value+'-'+formData.phone
      }
      // formData.phone=selectedCountry?.value+formData.phone
      const res = await staffService.createStaff(playload);
      console.log("resss===", res)
      if (res?.code == 200) {
        toast.success(res?.message)
        navigate('/staff-management')
      } else {
        toast.error(res?.message)
      }
    } else {
      // Set validation errors
      setShowError(true);
      console.log(validationErrors,"validation errors")
      setErrors(validationErrors);
    }
  };

  const handleRoleChange = (selectedOption) => {
    let validationErrors = "";
    if (selectedOption.value == "") {
      validationErrors = "Role is required";
    } else {
      validationErrors = "";
    }
    setShowError(true);
    setErrors({ ...errors, role: validationErrors });
    setFormData({ ...formData, role: selectedOption.value });
  };




  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className=" align-items-center mb-4">
          <Card>
            <Card.Header>
              <h4 className="card-title w-100">Add New Staff</h4>
            </Card.Header>
            <Card.Body>
              <Form className="row" onSubmit={handleSubmit}>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="role">
                    <Form.Label>Role</Form.Label>
                    <Select
                      options={roleOption}
                      onChange={handleRoleChange}
                      value={roleOption.find(
                        (option) => option.value === formData.role
                      )}
                      placeholder="Select role"
                      styles={theme == "dark" ? darkModeStyles2 :lightModeStyles2}
                      isInvalid={showError && !!errors.role}
                    />
                    <Form.Text className="text-danger small">
                      {errors.role !== "" && showError && "Role is required"}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="country">
                    <Form.Label>Country Code</Form.Label>
                    <Select
                      options={countries.map((country, index) => ({
                        value: country.countryCallingCode,
                        label: (
                          <div className="flex items-center gap-2">
                            <img src={country.flags.svg} alt={country.name} className="h-5 w-5 rounded-full object-cover" />
                            <span>{country.name}</span>
                          </div>
                        ),
                      }))}
                      onChange={(value) => { setSelectedCountry(value) }}
                      value={selectedCountry}
                      placeholder="Select Country"
                      isDisabled={true}
                      styles={theme == "dark" ? darkModeStyles2 :lightModeStyles2}
                      isInvalid={showError && !!errors.countryCode}
                    />
                   <Form.Text className="text-danger small">
                      {errors.countryCode !== "" && showError && errors.countryCode }
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <div className="d-flex justify-content-center mt-5">
                  <Button variant="primary" type="submit" className="mx-4">
                    Submit
                  </Button>
                  <Button variant="danger" type="button" onClick={() => navigate("/staff-management")}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddNewSatff;
