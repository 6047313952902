
import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const CurrencyService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
  const dispatch = useDispatch();
const axiosInstance = createAxiosInstance(bearerToken);
  const createCurrency = async (requestData) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .post(`/currency`, requestData, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        // console.log("created =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Creation failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getCurrencyList = async ({ page, limit, searchBy }) => {
    dispatch(startApiRequest());
      return await axiosInstance
        // /game-setting?page=1&limit=10&searchBy=
      .get(`/currency?page=${page}&limit=${limit}&searchBy=${searchBy}`,  { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        // console.log(" =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateCurrency = async (id, requestData) => {
      return await axiosInstance
        // admin/game-setting/667d505a16490b9f26f75ded
      .patch(`/currency/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("updated =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Updation failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
 
  // /game/list/dropdown
  const deleteCurrency = async (id) => {
    return await axiosInstance
      .delete(`/currency/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };


  const getCurrencyById = async (id) => {
      return await axiosInstance
        // admin/game-setting/667d505a16490b9f26f75ded
      .get(`/currency/${id}`,  { headers: {} })
      .then((response) => {
        // console.log("F =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  return {
    createCurrency,
    getCurrencyById,
    getCurrencyList,
    updateCurrency,
    deleteCurrency
    // deleteGame
  };
};

export default CurrencyService;
