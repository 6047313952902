import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import GameService from "../../service/api/game.service";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./user.css";
import { Button, Card, Row, Col, Nav, ListGroup, Table, Badge } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import MerchantService from "../../service/api/merchant.service";
import PageBreadcrumb from "../../common/PageBreadcrum";
import globalSwal from "../../common/swalService";

export default function GameList() {
  const location = useLocation();
  let id = location?.state?.id || "";
  let category = location?.state?.category || null;
  
  const gameService = GameService();
  const authService = AuthService();
  const merchantService = MerchantService();
  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [gameList, setGameList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: '',
    limit: 10,
    totalDocs: 0
  });
  const [columns, setColumns] = useState(["name", "gameUnique", "underMaintenance", "status", "id"]);
  const [merchantOption, setMerchantOption] = useState(null);

  const getMerchantList = async () => {
    const res = await merchantService.getAllMerchants();
    if (res?.code === 200) {
      // setMerchantList(res?.data);
      let data = res?.data.map((item) => ({ value: item.id, label: item.name }));
      setMerchantOption(data);
    }
    console.log(" and ", merchantOption)
  }
  useEffect(() => {

    getMerchantList();
  }, []);
  useEffect(() => {
    getList();
    authService.getLoginData()
  }, []);

  const getList = async () => {
    const res = await gameService.getGame({
      page: pagination?.page,
      limit: pagination?.limit,
      searchBy: pagination?.searchBy,
      category: id
    });
    if (res?.code === 200) {
      console.log(res, "========Response from game managemanet")
      setGameList(res?.data?.docs);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pages: res?.data.pages,
        totalDocs: res?.data.totalDocs,
        limit: res?.data.limit
      }));
    } else {
      console.error(res?.message);
      // toast.error(res?.message);
    }
  };

  useEffect(() => {
    getList();
    authService.getLoginData()
  }, [pagination.searchBy, pagination.page,id]);

  // Define HTML for status badges
  const getStatusBadge = (status) => {
    if (status === 1) {
      return <Badge bg={'success'} className="badge badge-success">Active</Badge>;
    } else if (status === 0) {
      return <Badge bg={'pink'} className="badge badge-danger">Inactive</Badge>;
    }
  };

  // Define HTML for action column
  const getActionDropdown = (id, status) => {
    console.log("id", id);
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => handleStatusUpdate(id)}>
            {status === 1 ? "Deactivate" : "Activate"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };



  const handleUpdate = (id) => {
    const state = { id: id };
    navigate(`/game-management/edit`, { state });
  };
  const handleView = (id) => {
    const state = { id: id };
    navigate(`/game-management/view`, { state });
  };

  const handleStatusUpdate = async (id) => {
    // const state = {id: id}
    // navigate(`/role-management/edit`, {state})
    const role = gameList?.filter((item) => item.id === id);
    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to change the current status?",
      onConfirm: async () => {

        const res = await gameService.updateUser(role[0].id, {
          ...role[0],
          status: role[0].status == 1 ? 0 : 1,
        });
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Status has been changed successfully.",
          });
          toast.success(res?.message || "Status updated successfully.");
          getList();
        } else {
          toast.error(res?.message || "Failed to update status.");
        }

      },
    });

  };

  const handleDelete = async (id) => {
    // const state = {id: id}
    // navigate(`/role-management/edit`, {state})

    globalSwal.confirm({
      title: "Are you sure?",
      text: "Do you really want to delete?",
      onConfirm: async () => {


        const res = await gameService.deleteGame(id);
        if (res?.code === 200) {
          globalSwal.success({
            title: "Success!",
            text: "Game has been deleted successfully.",
          });
          toast.success(res?.message);
          getList();
          setPagination({ ...pagination, page: 1 })
        } else {
          toast.error(res?.message || "Failed to delete.");
        }

      },
    });
    // const role = gameList?.filter((item) => item.id === id);
    // const res = await gameService.deleteGame(id);
    // if (res?.code == 200) {
    //   toast.success(res?.message);
    //   getList();
    // } else {
    //   console.error(res?.message);
    //   toast.error(res?.message);
    // }
  };

  const getMaintainanceBadge = (status) => {
    if (status) {
      return (
        <Badge bg={"success"} className="badge badge-success">
          Yes
        </Badge>
      );
    } else {
      return (
        <Badge bg={"pink"} className="badge badge-danger">
          No
        </Badge>
      );
    }
  };



  // Map the columns to include HTML for status badges
  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "status") {
      return {
        header: "Status",
        accessor: column,
        Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
      };
    } else if (column.toLowerCase() === "id") {
      return {
        header: " ",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row?.status), // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "merchant") {
      return {
        header: "Merchant",
        accessor: column,
        Cell: ({ value }) => <MerchantPopup merchantOption={merchantOption} merchant={value} />, // Render action
      }
    }
    else if (column.toLowerCase() === "name") {
      return {
        header: "Name",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "gameunique") {
      // underMaintenance
      return {
        header: "Unique Id",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "undermaintenance") {
      // underMaintenance
      return {
        header: "Under Maintenance",
        accessor: column,
        Cell: ({ value }) => getMaintainanceBadge(value)
      };
    }
    else {
      return column;
    }
  });


  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();
    // if (value.length > 3 || value.length === 0) {
    // }
    setPagination((prev) => ({ ...prev, searchBy: value }));
  };

  const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);
  console.log(category,"category===============")
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>

          <div className=" align-items-center ">
            <Card.Header>

              {!category ? <PageBreadcrumb title={"Games"} />

                :
                <Row>
                  <Col xs={12}>
                    <div className="page-title-box  text-dark">
                      <div className="page-title-right">


                        <ol className="breadcrumb fs-sm ">
                          <h4 className="breadcrumb-item"><Link onClick={() => {
                            id = "";
                            category = "";
                          }} to="/game-management">Games</Link></h4>
                          <h4 className="breadcrumb-item " aria-current="page">{category}</h4>
                        </ol>
                      </div>
                    </div>
                  </Col>
                </Row>}

            </Card.Header>
            <TableWithPagination
              data={[...gameList]}
              itemsPerPage={pagination?.limit}
              columns={columnsWithHTML}
              currentPage={pagination?.page}
              totalPages1={pagination?.totalPages}
              totalData={pagination?.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              handleSearchChange={debouncedHandleSearchChange}
              moduleId={4}
              redirectUrl={'/game-management/add'}
            />
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}



const MerchantPopup = ({ merchant, merchantOption }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(merchant, 'merchant popup', merchantOption)
  const result = merchantOption?.filter(option => merchant?.includes(option.value));

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        View Merchant
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Merchant Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            result?.map((item, index) => (
              <div key={index} style={{ display: 'flex', marginBottom: '10px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                <strong>{index + 1}.</strong>
                <p style={{ margin: '0' }}>{item.label}</p>
              </div>
            ))
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};