import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import DashboardService from "../../service/api/dashboard.service";
import DateFilter from "./date-filter";
import { endOfMonth, format, startOfMonth } from "date-fns";
import cronParser from 'cron-parser';
import AdminCronSettings from "./AdminCronSetting";
import moment from "moment/moment";
import TransactionStatistics from "./TransactionsStatistics";
import UserStatistics from "./UserStatistics";
import { useSelector } from "react-redux";
import { BorderSpinner, Loader } from "../../common/Spinner";
import SimplifiedLotteryGraphs from "./LotteryChart";
import LotteryStats from "./LotteryStats";
import MerchantFilter from "./merchant-filter";

export default function LotteryStatistics() {
    const dashboardService = DashboardService();
    const [statistics, setStatistics] = useState({});
    const [data, setData] = useState(null);
    const [isLoading,setIsLoading] = useState(true);
    const today = new Date();
    const initialStartDate = startOfMonth(today);
    const initialEndDate = endOfMonth(today);
    const skin = useSelector((state) => state.ui.skin);
    const [merchantId, setMerchantId] = useState('')

    const [dateRange, setDateRange] = useState({
        startDate: format(initialStartDate, 'yyyy-MM-dd'),
        endDate: format(initialEndDate, 'yyyy-MM-dd')
    });
    // const [dateRange, setDateRange] = useState({
    //     startDate: format(today, 'yyyy-MM-dd'),
    //     endDate: format(today, 'yyyy-MM-dd')
    // })

    const axisColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : 'rgb(224, 224, 224)';
    const labelColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : '#41505f';


    const [cronExpression, setCronExpression] = useState(localStorage.getItem('cronExpression') || '');
    const timeoutId = useRef(null);

    useEffect(() => {
        localStorage.setItem('cronExpression', cronExpression);
        getStatistics(); // Initial fetch
        scheduleFetch();
        getLotteryData();

        // Cleanup function to clear timeout if cronExpression or dateRange changes
        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [cronExpression, dateRange,merchantId]);

    // useEffect(() => {
    //     getStatistics();
    // }, [dateRange])

    const scheduleFetch = () => {
        try {
            const adjustedCron = cronExpression.split(' ').slice(1, 6).join(' ');
            const interval = cronParser.parseExpression(adjustedCron);
            const now = new Date();
            const nextExecution = interval.next().toDate();
            const delay = nextExecution - now;
            // console.log(delay, "execution delay", interval, interval.next().toDate(), adjustedCron, cronExpression)
            if (delay > 0) {
                const id = setTimeout(() => {
                    getStatistics();
                    getLotteryData();
                    scheduleFetch(); // Schedule next fetch
                }, delay);
                timeoutId.current = id; // Store timeout ID to clear it later
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getStatistics = async () => {
        try {
            const payload = {
                ...dateRange,
                merchantId:merchantId
            }
            const res = await dashboardService.getLotteryGraphData(payload);
            // console.log(res.data, "console from lottery metrics");
            setStatistics(res?.data); // Assuming the API response is in the expected format
        } catch (error) {
            console.error("Error fetching bet statistics", error);
        }
    }
    const getLotteryData = async() => {
        try {
            const payload = {
                ...dateRange,
                merchantId:merchantId
            }
          const res = await dashboardService.getLotteryDashboardData(payload);
          if (res.code === 200) {
            // console.log(res);
            setData(res?.data);
          }
          else {
            throw new Error(res.message);
          }
        } catch (error) {
          console.error(error);
        } finally { 
          setIsLoading(false);
        }
      }
    
    
    
      


    const processData = (data, path, valueKey = 'count') => {
        if (!data || !path) return [];

        // Access the data at the given path using optional chaining
        const targetData = path.split('.').reduce((acc, key) => acc && acc[key], data);

        if (!targetData) {
            console.warn(`No data found at path: ${path}`);
            return [];
        }

        // Map and filter the data
        return targetData.map((item) => {
            const timestamp = new Date(item._id).getTime(); // Convert _id to timestamp
            const value = Number(item[valueKey]); // Ensure the value (count or activeUsersCount) is a number
            if (isNaN(timestamp) || isNaN(value)) {
                console.warn("Invalid data point:", { dateTime: item._id, [valueKey]: item[valueKey] });
            }
            return {
                x: timestamp,
                y: value
            };
        }).filter(point => !isNaN(point.x) && !isNaN(point.y)) || [];
    };



    // console.log("Processed Data:", processedData);

    // activeUsersTrend




    const handleDateChange = (range) => {
        setDateRange(range);
    };

  


   
    // if (isLoading) {
    //     return <React.Fragment>
    //         <Header  />
    //         <Sidebar />
    //         <div className="main main-app p-3 p-lg-4  ">
    //             <div className="d-flex mt-5 justify-content-center align-content-center">

    //                 <BorderSpinner />
    //             </div>

    //         </div>
    //     </React.Fragment>
    // }

    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="/bet-statics">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Lottery Metrics</li>
                        </ol>
                        <h4 className="main-title mt-2 mb-0">Lottery Metrics</h4>

                    </div>
                    <div className="d-flex flex-wrap gap-2 mt-3 mt-md-0">
                        <MerchantFilter merchantId={merchantId} setMerchantId={setMerchantId} />
                        <DateFilter onDateChange={handleDateChange} type={'lottery'} />
                        <AdminCronSettings onSave={(expression) => setCronExpression(expression)} />
                    </div>
                </div>
               

                {!isLoading ? <>
                    <LotteryStats skin={skin} data={data} />
                    <SimplifiedLotteryGraphs skin={skin} data={statistics} />
                </> : <>
                    <div className="d-flex justify-content-center align-content-center " style={{ height: '60vh' }} >
                        <Loader />
                    </div>
                </>}

                {/* <Footer /> */}
            </div>
        </React.Fragment>
    );
}

