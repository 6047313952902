import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// import './dateFilter.css'
import { useSelector } from "react-redux";
import './dateFilter.css'
import { NumericFormat } from 'react-number-format';
const UserStatistics = ({ statistics }) => {
    const skin = useSelector((state) => state.ui.skin);
    return (
        <>
            <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                <Col lg={3} md={6} sm={12} className="mb-1">
                    <Card>
                        <Card.Body>
                            <h6>Total Users</h6>
                            <h4>
                                {/* {statistics?.totalUsers} */}
                                <NumericFormat
                                    value={statistics?.totalUsers || '0'}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    // prefix={'₹'}
                                    allowedDecimalSeparators={['%']}
                                    allowNegative
                                    decimalScale={2}
                                    thousandsGroupStyle="lakh"
                                />
                            </h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} md={6} sm={12} className="mb-1">
                    <Card>
                        <Card.Body>
                            <h6>Paid Users</h6>
                            <h4>
                                {/* {statistics?.paidUsers} */}
                                <NumericFormat
                                    value={statistics?.paidUsers || '0'}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    // prefix={'₹'}
                                    allowedDecimalSeparators={['%']}
                                    allowNegative
                                    decimalScale={2}
                                    thousandsGroupStyle="lakh"
                                />

                            </h4>
                        </Card.Body>
                    </Card>
                </Col>



            </Row>
            <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                <Col lg={6} md={12} sm={12} className="mb-1">
                    <Card>
                        <Card.Body>
                            <h5>New Paid Users</h5>
                            <Row>
                                <Col lg={6} md={6} sm={12} className="mb-1">
                                    <div className="stat-item ">
                                        <div className="stat-icon bg-success text-white shadow-lg">
                                            <i className="fas fa-calendar-day"></i>

                                        </div>
                                        <div>
                                            <h4>
                                                {/* {Number(statistics?.newPaidUsers?.daily)?.toFixed(0)} */}
                                                <NumericFormat
                                                    value={statistics?.newPaidUsers?.daily}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Daily</h6>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} sm={12} className="mb-1">
                                    <div className="stat-item">
                                        <div className="stat-icon bg-warning text-white shadow-lg">
                                            <i className="fas fa-calendar-week"></i>

                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.newPaidUsers?.weekly} */}
                                                <NumericFormat
                                                    value={statistics?.newPaidUsers?.weekly}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Weekly</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mb-1">
                                    <div className="stat-item">
                                        <div className="stat-icon">
                                            <i className="fas fa-calendar-alt"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.newPaidUsers?.monthly} */}
                                                <NumericFormat
                                                    value={statistics?.newPaidUsers?.monthly}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Monthly</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mb-1">
                                    <div className="stat-item">
                                        <div className="stat-icon bg-info text-white shadow-lg">

                                            <i class="fa-regular fa-calendar"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.newPaidUsers?.yearly || '0'} */}
                                                <NumericFormat
                                                    value={statistics?.newPaidUsers?.yearly || '0'}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            
                                            </h4>
                                            <h6>Yearly</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6} md={12} sm={12} className="mb-1">
                    <Card>
                        <Card.Body>
                            <h5>New Users</h5>
                            <Row>
                                <Col lg={6} md={6} sm={12} className="mb-1">
                                    <div className="stat-item ">
                                        <div className="stat-icon bg-success text-white shadow-lg">
                                            <i className="fas fa-calendar-day"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {Number(statistics?.newUsers?.daily)?.toFixed(0)} */}
                                                <NumericFormat
                                                    value={statistics?.newUsers?.daily}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Daily</h6>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} sm={12} className="mb-1">
                                    <div className="stat-item">
                                        <div className="stat-icon bg-warning text-white shadow-lg">
                                            <i className="fas fa-calendar-week"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.newUsers?.weekly} */}
                                                <NumericFormat
                                                    value={statistics?.newUsers?.weekly}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Weekly</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mb-1">
                                    <div className="stat-item">
                                        <div className="stat-icon ">
                                            <i className="fas fa-calendar-alt"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.newUsers?.monthly} */}
                                                <NumericFormat
                                                    value={statistics?.newUsers?.monthly}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Monthly</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mb-1">
                                    <div className="stat-item">
                                        <div className="stat-icon bg-info text-white shadow-lg">

                                            <i class="fa-regular fa-calendar"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.newUsers?.yearly || '0'} */}
                                                <NumericFormat
                                                    value={statistics?.newUsers?.yearly}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            
                                            </h4>
                                            <h6>Yearly</h6>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default UserStatistics;
