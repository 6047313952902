import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import CurrencyService from "../../service/api/currency.service";
import InfoComponent from "../../common/Info";
import PlatformService from "../../service/api/platform-settings.service";

const AddNewSettings = () => {
    const { skin, setSkin } = useSkinSwitcher();
    const platFormService = PlatformService();
    const currencyService = CurrencyService();
    const [currencyList, setCurrencyList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        type: "",
        currencyId: "",
        settings: {}
    });
    const [selectedType, setSelectedType] = useState("");

    const getCurrencyList = async () => {
        try {
            const res = await currencyService.getCurrencyList({
                page: 1,
                limit: 20,
                searchBy: ''
            });
            if (res?.code === 200) {
                setCurrencyList(res?.data?.docs);
            } else {
                toast.error(res?.message || "Failed to fetch currency list.");
            }
        } catch (error) {
            toast.error("An unexpected error occurred while fetching currency list.");
        }
    };

    useEffect(() => {
        getCurrencyList();
    }, []);

    const handleTypeChange = (e) => {
        const selected = e.target.value;
        setSelectedType(selected);
        setFormData((prevData) => ({
            ...prevData,
            type: selected,
            settings: {}  // Reset settings based on selected type
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            type: ""
        }));
    };

    const handleCurrencyChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            currencyId: e.target.value
        }));
        

        setErrors((prevErrors) => ({
            ...prevErrors,
            currencyId: ""
        }));
    };

    const handleSettingsChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            settings: {
                ...prevData.settings,
                [name]: Number(value),
            },
        }));
        setErrors({ ...errors, [name]: "" });
    };

    const validateForm = () => {
        const validationErrors = {};

         // Basic validation for required fields
         if (!formData.currencyId) validationErrors.currencyId = "Currency is required";
         if (!formData.type) validationErrors.type = "Setting Type is required";
 
         // Custom validation for each setting type
         if (formData.type === "cooldownPeriods") {
             if (!formData.settings.withdrawalCooldownHours) {
                 validationErrors.withdrawalCooldownHours = "Withdrawal Cooldown Hours is required";
             }
             else if (!formData.settings.withdrawalCooldownHours && formData.settings.withdrawalCooldownHours<0) {
                validationErrors.withdrawalCooldownHours = "Withdrawal Cooldown Hours must be a positive number";
                 
             }
             if (!formData.settings.loginCooldownMinutes) {
                 validationErrors.loginCooldownMinutes = "Login Cooldown Minutes is required";
             }
             else if (!formData.settings.loginCooldownMinutes && formData.settings.loginCooldownMinutes<0) {
                validationErrors.withdrawalCooldownHours = "Login Cooldown Minutes must be a positive number";
                 
             }
         } else if (formData.type === "betLimits") {
             if (!formData.settings.minBetAmount) {
                 validationErrors.minBetAmount = "Minimum Bet Amount is required";
             } else if (formData.settings.minBetAmount <= 0) {
                 validationErrors.minBetAmount = "Minimum Bet Amount must be greater than zero";
             }
             if (!formData.settings.maxBetAmount) {
                 validationErrors.maxBetAmount = "Maximum Bet Amount is required";
             } else if (formData.settings.maxBetAmount <= formData.settings.minBetAmount) {
                 validationErrors.maxBetAmount = "Maximum Bet Amount must be greater than Minimum Bet Amount";
             }
         } else if (formData.type === "withdrawalLimits") {
             if (!formData.settings.minWithdrawalAmount) {
                 validationErrors.minWithdrawalAmount = "Minimum Withdrawal Amount is required";
             } else if (formData.settings.minWithdrawalAmount <= 0) {
                 validationErrors.minWithdrawalAmount = "Minimum Withdrawal Amount must be greater than zero";
             }
             if (!formData.settings.maxWithdrawalAmount) {
                 validationErrors.maxWithdrawalAmount = "Maximum Withdrawal Amount is required";
             } else if (formData.settings.maxWithdrawalAmount <= formData.settings.minWithdrawalAmount) {
                 validationErrors.maxWithdrawalAmount = "Maximum Withdrawal Amount must be greater than Minimum Withdrawal Amount";
             }
         }
        
        setErrors(validationErrors);
        setShowError(Object.keys(validationErrors).length > 0);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!validateForm()) {
            setIsSubmitting(false);
            return;
        }

        try {
            const res = await platFormService.createSettings(formData);
            if (res?.code === 200) {
                toast.success(res?.message);
                navigate("/platform-settings/list");
            } else {
                toast.error(res?.message);
            }
        } catch (error) {
            toast.error("An error occurred while submitting the form.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Card.Header>
                        <h4 className="card-title w-100">Add Platform Setting</h4>
                    </Card.Header>
                    <Card.Body>
                        <Form className="row" onSubmit={handleSubmit}>
                            <Col md={4} className="mt-3">
                                <Form.Group controlId="settingType">
                                    <Form.Label>Setting Type</Form.Label>
                                    <InfoComponent message="Select the setting type (Bet Limits or Withdrawal Limits)." />
                                    <Form.Select
                                        name="settingType"
                                        value={formData.type}
                                        onChange={handleTypeChange}
                                        isInvalid={showError && !!errors.type}
                                    >
                                        <option value="">Select Setting Type</option>
                                        <option value="cooldownPeriods">Cooldown Periods</option>
                                        <option value="betLimits">Bet Limits</option>
                                        <option value="withdrawalLimits">Withdrawal Limits</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.type}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {formData.type && (
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="currencyId">
                                        <Form.Label>Currency</Form.Label>
                                        <InfoComponent message="Select the currency for this setting." />
                                        <Form.Select
                                            name="currencyId"
                                            value={formData.currencyId}
                                            onChange={handleCurrencyChange}
                                            isInvalid={showError && !!errors.currencyId}
                                        >
                                            <option value="">Select Currency</option>
                                            {currencyList.map((currency) => (
                                                <option key={currency.id} value={currency.id}>
                                                    {currency.currencyCodes}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.currencyId}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            )}

                            {formData.type === "cooldownPeriods" && (
                                <>
                                    <Col md={4} className="mt-3">
                                        <Form.Group controlId="withdrawalCooldownHours">
                                            <Form.Label>Withdrawal Cooldown Hours</Form.Label>
                                            <Form.Control
                                                type="number"
                                                min={0}
                                                name="withdrawalCooldownHours"
                                                value={formData.settings.withdrawalCooldownHours || ""}
                                                onChange={handleSettingsChange}
                                                isInvalid={showError && !!errors.withdrawalCooldownHours}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.withdrawalCooldownHours}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mt-3">
                                        <Form.Group controlId="loginCooldownMinutes">
                                            <Form.Label>Login Cooldown Minutes</Form.Label>
                                            <Form.Control
                                                type="number"
                                                min={0}
                                                name="loginCooldownMinutes"
                                                value={formData.settings.loginCooldownMinutes || ""}
                                                onChange={handleSettingsChange}
                                                isInvalid={showError && !!errors.loginCooldownMinutes}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.loginCooldownMinutes}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </>
                            )}

                            {formData.type === "betLimits" && (
                                <>
                                    <Col md={4} className="mt-3">
                                        <Form.Group controlId="minBetAmount">
                                            <Form.Label>Minimum Bet Amount</Form.Label>
                                            <Form.Control
                                                type="number"
                                                min={0}
                                                name="minBetAmount"
                                                value={formData.settings.minBetAmount || ""}
                                                onChange={handleSettingsChange}
                                                isInvalid={showError && !!errors.minBetAmount}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.minBetAmount}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mt-3">
                                        <Form.Group controlId="maxBetAmount">
                                            <Form.Label>Maximum Bet Amount</Form.Label>
                                            <Form.Control
                                                type="number"
                                                min={0}
                                                name="maxBetAmount"
                                                value={formData.settings.maxBetAmount || ""}
                                                onChange={handleSettingsChange}
                                                isInvalid={showError && !!errors.maxBetAmount}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.maxBetAmount}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </>
                            )}

                            {formData.type === "withdrawalLimits" && (
                                <>
                                    <Col md={4} className="mt-3">
                                        <Form.Group controlId="minWithdrawalAmount">
                                            <Form.Label>Minimum Withdrawal Amount</Form.Label>
                                            <Form.Control
                                                type="number"
                                                min={0}
                                                name="minWithdrawalAmount"
                                                value={formData.settings.minWithdrawalAmount || ""}
                                                onChange={handleSettingsChange}
                                                isInvalid={showError && !!errors.minWithdrawalAmount}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.minWithdrawalAmount}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mt-3">
                                        <Form.Group controlId="maxWithdrawalAmount">
                                            <Form.Label>Maximum Withdrawal Amount</Form.Label>
                                            <Form.Control
                                                type="number"
                                                min={0}
                                                name="maxWithdrawalAmount"
                                                value={formData.settings.maxWithdrawalAmount || ""}
                                                onChange={handleSettingsChange}
                                                isInvalid={showError && !!errors.maxWithdrawalAmount}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.maxWithdrawalAmount}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </>
                            )}

                            <Col md={12} className="mt-5">
                                <Button disabled={isSubmitting} variant="primary" type="submit">
                                    {isSubmitting ? 'Please wait...' : 'Submit'}
                                </Button>
                                <Link to="/platform-settings/list" className="btn btn-danger ms-2">
                                    Cancel
                                </Link>
                            </Col>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default AddNewSettings;
