import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import BannerService from "../../service/api/banner.service";
import { BorderSpinner } from "../../common/Spinner";
import InfoComponent from "../lottery-management/Info";
import globalSwal from "../../common/swalService";

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

const EditBanner = () => {
  const bannerService = BannerService();
  const theme = useSelector((state) => state.ui.skin);
  const location = useLocation();
  const { id } = location.state;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    beforeSignInBanner: [],
    afterSignInBanner: [],
  });

  const [beforeSignInPreview, setBeforeSignInPreview] = useState([]);
  const [afterSignInPreview, setAfterSignInPreview] = useState([]);
  const [selectedType, setSelectedType] = useState("beforeSignInBanner");

  const getBanner = async () => {
    try {
      const res = await bannerService.getBannerById(id);
      if (res?.code === 200) {
        setFormData({
          name: res?.data?.name,
          beforeSignInBanner: res?.data?.beforeSignInBanner || [],
          afterSignInBanner: res?.data?.afterSignInBanner || [],
        });

        setBeforeSignInPreview(
          res?.data?.beforeSignInBanner?.map(
            (image) => `${imageBaseUrl}/${image.url}`
          )
        );

        setAfterSignInPreview(
          res?.data?.afterSignInBanner?.map(
            (image) => `${imageBaseUrl}/${image.url}`
          )
        );
      } else {
        throw new Error(res?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const preview = URL.createObjectURL(file);

    if (selectedType === "beforeSignInBanner") {
      setFormData((prev) => ({
        ...prev,
        beforeSignInBanner: [
          ...prev.beforeSignInBanner,
          { link: "", url: file },
        ],
      }));
      setBeforeSignInPreview((prev) => [...prev, preview]);
    } else {
      setFormData((prev) => ({
        ...prev,
        afterSignInBanner: [
          ...prev.afterSignInBanner,
          { link: "", url: file },
        ],
      }));
      setAfterSignInPreview((prev) => [...prev, preview]);
    }
  };

  const handleRemoveImage = async (url, type) => {
    try {
      const payload =
        type === "beforeSignInBanner"
          ? { removeBeforeSignInBanner: [url] }
          : { removeAfterSignInBanner: [url] };
      
          globalSwal.confirm({
            title: "Are you sure?",
            text: "Do you really want to delete?",
            onConfirm: async () => {


              const res = await bannerService.updateBannerStatus(id, payload);

              if (res?.code === 200) {
                globalSwal.success({
                  title: "Success!",
                  text: "Banner removed successfully!",
              });
                // toast.success("Banner removed successfully!");
                if (type === "beforeSignInBanner") {
                  setBeforeSignInPreview((prev) =>
                    prev.filter((preview) => !preview.includes(url))
                  );
                  setFormData((prev) => ({
                    ...prev,
                    beforeSignInBanner: prev.beforeSignInBanner.filter(
                      (item) => item.url !== url
                    ),
                  }));
                } else {
                  setAfterSignInPreview((prev) =>
                    prev.filter((preview) => !preview.includes(url))
                  );
                  setFormData((prev) => ({
                    ...prev,
                    afterSignInBanner: prev.afterSignInBanner.filter(
                      (item) => item.url !== url
                    ),
                  }));
                }
              } else {
                throw new Error(res?.message);
              }

            },
        });

      
    } catch (error) {
      toast.error("Failed to remove banner.");
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);

      formData.beforeSignInBanner.forEach((banner,index) => {
        // if (banner.url) {

          formDataToSend.append(`beforeSignInBanner[${index}].file`, banner.url);
        // }
        // if (banner.link) {
          formDataToSend.append(`beforeSignInBanner[${index}].link`, banner.link);
        // }
        // formDataToSend.append("beforeSignInBanner", item.url);
        // formDataToSend.append("beforeSignInBannerLink", item.link || "");
      });

      formData.afterSignInBanner.forEach((banner,index) => {
        // if (banner.url) {

          formDataToSend.append(`afterSignInBanner[${index}].file`, banner.url);
        // }
        // if (banner.link) {
          formDataToSend.append(`afterSignInBanner[${index}].link`, banner.link);
        // }
        // formDataToSend.append("afterSignInBanner", item.url);
        // formDataToSend.append("afterSignInBannerLink", item.link || "");
      });

      const res = await bannerService.updateBannerStatus(id, formDataToSend);
      if (res?.code === 200) {
        toast.success("Banners updated successfully!");
        navigate("/banner");
      } else {
        throw new Error(res?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLinkChange = (e, index,type) => {
    const { value } = e.target;

    if (type === "beforeSignInBanner") {
      setFormData((prev) => {
        const updatedBanners = [...prev.beforeSignInBanner];
        updatedBanners[index].link = value;
        return { ...prev, beforeSignInBanner: updatedBanners };
      });
    } else {
      setFormData((prev) => {
        const updatedBanners = [...prev.afterSignInBanner];
        updatedBanners[index].link = value;
        return { ...prev, afterSignInBanner: updatedBanners };
      });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
            <h4 className="card-title w-100">Edit Banner</h4>
          </Card.Header>
          <Card.Body>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center"><BorderSpinner /></div>
            ) : (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name" className="mt-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                      type="text"
                      readOnly
                      value={formData.name}
                    
                  />
                </Form.Group>
                  <Card className="mt-4">
                    <Card.Header>
                    <h4 className="card-title w-100">Upload New Image</h4>

                    </Card.Header>
                    <Card.Body>
                    <Form.Group className="mt-3">
                  <Form.Label>Banner Type</Form.Label>
                  <Form.Select
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    <option value="beforeSignInBanner">
                      Before Sign-In Banner
                    </option>
                    <option value="afterSignInBanner">
                      After Sign-In Banner
                    </option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="fileInput" className="mt-3">
                  <Form.Label>Add Image</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Form.Group>
                    </Card.Body>
                </Card>

                <div className="mt-3">
                  <h5>Before Sign-In Preview</h5>
                    <div className="d-flex flex-wrap gap-3">
                      {beforeSignInPreview.length===0 && <div>No Records</div>}
                    {beforeSignInPreview.map((src, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", width: "140px" }}
                        className="image-preview-container"
                      >
                        <img
                          src={src}
                          alt={`Before Sign-In ${index}`}
                          style={{
                            width: "100%",
                            height: "100px",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                        <div className="image-hover">
                          <button
                          type="button"
                            onClick={() =>
                              handleRemoveImage(
                                formData.beforeSignInBanner[index].url,
                                "beforeSignInBanner"
                              )
                            }
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              background: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                            }}
                          >
                            X
                          </button>

                          <div className="d-flex ">
                          <Form.Control
                            type="text"
                            placeholder="Link"
                            value={formData.beforeSignInBanner[index].link || ""}
                            onChange={(e) => handleLinkChange(e, index,'beforeSignInBanner')}
                            className="mt-2"
                          />
                          <InfoComponent message={`Link should follow the format: e.g., "casino" or "casino/crash.Please do not enter domain url."`} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mt-3">
                  <h5>After Sign-In Preview</h5>
                    <div className="d-flex flex-wrap gap-3">
                    {afterSignInPreview.length===0 && <div>No Records</div>}
                    {afterSignInPreview.map((src, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", width: "140px" }}
                        className="image-preview-container"
                      >
                        <img
                          src={src}
                          alt={`After Sign-In ${index}`}
                          style={{
                            width: "100%",
                            height: "100px",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                        <div className="image-hover">
                          <button
                            type="button"
                            onClick={() =>
                              handleRemoveImage(
                                formData.afterSignInBanner[index].url,
                                "afterSignInBanner"
                              )
                            }
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              background: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                            }}
                          >
                            X
                          </button>
                          <div className="d-flex ">

                          <Form.Control
                            type="text"
                            placeholder="Link"
                            value={formData.afterSignInBanner[index].link || ""}
                            onChange={(e) => handleLinkChange(e, index,'afterSignInBanner')}
                            className="mt-2"
                          />
                           <InfoComponent message={`Link should follow the format: e.g., "casino" or "casino/crash.Please do not enter domain url."`} />
  
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Updating..." : "Update Banner"}
                </Button>
              </Form>
            )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default EditBanner;



// import React, { useState, useEffect } from "react";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import Col from "react-bootstrap/Col";
// import Card from "react-bootstrap/Card";
// import Sidebar from "../../layouts/Sidebar";
// import Header from "../../layouts/Header";
// import { toast } from "react-toastify";
// import { useNavigate, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// import BannerService from "../../service/api/banner.service";
// import { BorderSpinner } from "../../common/Spinner";
// const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

// const EditBanner = () => {
//   const bannerService = BannerService();
//   const theme = useSelector((state) => state.ui.skin);
//   const location = useLocation();
//   const { id } = location.state;
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);


//   const navigate = useNavigate();
//   const [showError, setShowError] = useState(false);

//   const [errors, setErrors] = useState({});
//   const [formData, setFormData] = useState({
//     name: "",
//     beforeSignInBanner: [],
//     afterSignInBanner: [],
//   });

//   const [beforeSignInPreview, setBeforeSignInPreview] = useState([]);
//   const [afterSignInPreview, setAfterSignInPreview] = useState([]);

//   const getBanner = async () => {

//     try {
//       const res = await bannerService.getBannerById(id);
//     if (res?.code === 200) {
//       setFormData({
//         name: res?.data?.name,
//         beforeSignInBanner: res?.data?.beforeSignInBanner || [],
//         afterSignInBanner: res?.data?.afterSignInBanner || [],
//       });

//       setBeforeSignInPreview(
//         (res?.data?.beforeSignInBanner || []).map(
//           (image) => `${imageBaseUrl}/${image}`
//         )
//       );

//       setAfterSignInPreview(
//         (res?.data?.afterSignInBanner || []).map(
//           (image) => `${imageBaseUrl}/${image}`
//         )
//       );
//       }
//     else {
//       throw new Error(res?.message)
//       }
//     } catch (error) {
//       console.error(error)
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     getBanner();
//   }, []);

//   const handleFileChange = (e, type) => {
//     const files = Array.from(e.target.files);

//     const previews = files.map((file) => URL.createObjectURL(file));

//     if (type === "beforeSignInBanner") {
//       setFormData((prev) => ({
//         ...prev,
//         beforeSignInBanner: [...prev.beforeSignInBanner, ...files],
//       }));
//       setBeforeSignInPreview((prev) => [...prev, ...previews]);
//     } else if (type === "afterSignInBanner") {
//       setFormData((prev) => ({
//         ...prev,
//         afterSignInBanner: [...prev.afterSignInBanner, ...files],
//       }));
//       setAfterSignInPreview((prev) => [...prev, ...previews]);
//     }
    
//   };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setIsSubmitting(true);
  //     const formDataToSend = new FormData();
  
  //     formDataToSend.append("name", formData.name);
  
  //    console.log(formData.beforeSignInBanner,"beforeSignInBanner")
     
  //     formData.beforeSignInBanner.forEach((file) => {
  //       formDataToSend.append('beforeSignInBanner', file); // Use 'files' as the field name
  
  //     });
  
      
  //     formData.afterSignInBanner.forEach((file) => {
    
  //       formDataToSend.append('afterSignInBanner', file);
  //     });
  
     
  
      
  //     const res = await bannerService.updateBannerStatus(id, formDataToSend);
  //     if (res?.code === 200) {
  //       toast.success(res?.message);
  //       navigate("/banner");
  //     } else {
  //       throw new Error(res?.message);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

//   return (
//     <React.Fragment>
//       <Header />
//       <Sidebar />
//       <div className="main main-app p-3 p-lg-4">
//         <div className="align-items-center mb-4">
//           <Card>
//             <Card.Header>
//               <h4 className="card-title w-100">Edit Game</h4>
//             </Card.Header>
//             <Card.Body>
//               {isLoading ? <>
//                 <div className="d-flex mt-5 justify-content-center align-content-center">

//                     <BorderSpinner />
//               </div>



//               </>
//                 : (<Form className="row" onSubmit={handleSubmit}>
//                 <Col md={4} className="mt-3">
//                   <Form.Group controlId="name">
//                     <Form.Label>Name</Form.Label>
//                     <Form.Control
//                       type="text"
//                       placeholder="Enter name"
//                       value={formData.name}
//                       readOnly
//                     />
//                   </Form.Group>
//                 </Col>

//                 {/* Before Sign-In Banner */}
//                 <Col md={6} className="mt-3">
//                   <Form.Group controlId="beforeSignInBanner">
//                     <Form.Label>Before Sign-In Banner</Form.Label>
//                     <Form.Control
//                       type="file"
//                       multiple
//                       accept="image/jpeg,image/png,image/gif"
//                       onChange={(e) =>
//                         handleFileChange(e, "beforeSignInBanner")
//                       }
//                     />
//                   </Form.Group>
//                   <div className="mt-3 d-flex flex-wrap gap-2">
//                     {beforeSignInPreview.map((src, index) => (
//                       <img
//                         key={index}
//                         src={src}
//                         alt={`Before Sign-In Preview ${index}`}
//                         style={{
//                           width: "100px",
//                           height: "100px",
//                           objectFit: "cover",
//                           borderRadius: "8px",
//                         }}
//                       />
//                     ))}
//                   </div>
//                 </Col>

//                 {/* After Sign-In Banner */}
//                 <Col md={6} className="mt-3">
//                   <Form.Group controlId="afterSignInBanner">
//                     <Form.Label>After Sign-In Banner</Form.Label>
//                     <Form.Control
//                       type="file"
//                       multiple
//                       accept="image/jpeg,image/png,image/gif"
//                       onChange={(e) =>
//                         handleFileChange(e, "afterSignInBanner")
//                       }
//                     />
//                   </Form.Group>
//                   <div className="mt-3 d-flex flex-wrap gap-2">
//                     {afterSignInPreview.map((src, index) => (
//                       <img
//                         key={index}
//                         src={src}
//                         alt={`After Sign-In Preview ${index}`}
//                         style={{
//                           width: "100px",
//                           height: "100px",
//                           objectFit: "cover",
//                           borderRadius: "8px",
//                         }}
//                       />
//                     ))}
//                   </div>
//                 </Col>

//                 <div className="d-flex justify-content-center mt-5">
//                   <Button disabled={isSubmitting} variant="primary" type="submit" className="mx-4">
//                     {isSubmitting ?'Please wait...':'Submit'}
//                   </Button>
//                   <Button
//                     variant="danger"
//                     type="button"
//                     onClick={() => navigate("/banner")}
//                   >
//                     Cancel
//                   </Button>
//                 </div>
//               </Form>)}
    //         </Card.Body>
    //       </Card>
    //     </div>
    //   </div>
    // </React.Fragment>
//   );
// };

// export default EditBanner;

