import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, Form } from "react-bootstrap";
import PlatformService from "../../service/api/platform-settings.service";
import CurrencyService from "../../service/api/currency.service";
import InfoComponent from "../../common/Info";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { BorderSpinner } from "../../common/Spinner";

const ViewSettings = () => {
    const [settings, setSettings] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { id } = location.state;
    const platFormService = PlatformService();
    const currencyService = CurrencyService();
    const navigate = useNavigate();

    const getCurrencyList = async () => {
        try {
            const res = await currencyService.getCurrencyList({
                page: 1,
                limit: 20,
                searchBy: ""
            });
            if (res?.code === 200) {
                setCurrencyList(res?.data?.docs);
            } else {
                toast.error(res?.message || "Failed to fetch currency list.");
            }
        } catch (error) {
            toast.error("An unexpected error occurred while fetching currency list.");
        }
    };

    const getSettings = async () => {
        try {
            const res = await platFormService.getSettingById(id); // Fetch the settings by ID
            if (res?.code === 200) {
                setSettings(res?.data);
            } else {
                toast.error(res?.message || "Failed to fetch settings.");
            }
        } catch (error) {
            toast.error("An unexpected error occurred while fetching settings.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCurrencyList();
        getSettings();
    }, [id]);

    const typeObj = {
        cooldownPeriods: 'Cooldown Periods',
        betLimits: 'Bet Limits',
        withdrawalLimits:'Withdrawal Limits'
      }

    return (
        <React.Fragment>
            <Header />
            <Sidebar />

            <div className="main main-app p-3 p-lg-4">
                <div className="align-items-center mb-4">

                    <Card>
                        {/* <Card.Header>
                        <h4 className="card-title w-100">View Platform Setting</h4>
                    </Card.Header> */}
                        <Card.Header>
                            <div className="d-flex justify-content-between  text-end">
                                <h4 className="card-title ">View Platform Setting</h4>

                                {/* <Button
                                    variant="primary"
                                    type="button"
                                    className="ms-2"
                                    onClick={() => navigate("/user-management")}
                                >
                                    Back
                                </Button> */}
                                <Link to="/platform-settings/list" className="btn btn-primary ms-2">
                                    Back
                                </Link>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {(settings && !isLoading) ? (
                                <Form className="row">

                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Setting Type</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={typeObj[settings?.type]}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Currency</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={currencyList?.find(curr => curr?.id === settings?.currencyId)?.currencyCodes || "Unknown"}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>


                                    {settings?.type === "cooldownPeriods" && (
                                        <>

                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Withdrawal Cooldown Hours</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={settings?.settings?.withdrawalCooldownHours}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Login Cooldown Minutes</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={settings?.settings?.loginCooldownMinutes}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>

                                        </>
                                    )}

                                    {settings?.type === "betLimits" && (
                                        <>

                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Minimum Bet Amount</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={settings?.settings?.minBetAmount}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Maximum Bet Amount</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={settings?.settings?.maxBetAmount}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>

                                        </>
                                    )}

                                    {settings?.type === "withdrawalLimits" && (
                                        <>

                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Minimum Withdrawal Amount</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={settings?.settings?.minWithdrawalAmount}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Maximum Withdrawal Amount</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={settings?.settings?.maxWithdrawalAmount}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>

                                        </>
                                    )}
                                </Form>
                            ) : isLoading ? (
                                <div className="d-flex mt-5 justify-content-center align-content-center">

                                    <BorderSpinner />
                                </div>
                            ) : (

                                <div className="d-flex mt-5 justify-content-center align-content-center">


                                    <p>No settings found.</p>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>

            </div>
        </React.Fragment>
    );
};

export default ViewSettings;
