import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import Signin2 from "./pages/Signin2";
import { ToastContainer } from 'react-toastify';
import SocketService from './service/api/socket.service'
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import AuthService from "./service/api/auth.service";

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const socketService = SocketService();
  const authService = AuthService();
  // const isAuthenticated = localStorage.getItem("token");
  const user = useSelector((state) => state.user.user);
  // console.log("==========logged in user=======", user);
  const PrivateRoute = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.user.user);
    // const isAuthenticated = useSelector((state) => state.user.user); // Adjust this based on your state structure
    return isAuthenticated ? children : <Navigate to="/" />;
  };

  const AuthRoute = ({ children }) => {
    // const isAuthenticated = false;
    const isAuthenticated = useSelector((state) => state.user.user); // Adjust this based on your state structure
    return !isAuthenticated ? children : <Navigate to="/bet-statics" />;
  };

  useEffect(() => {
    // getList();
    if (user) {
      
      authService.getLoginData()
    }
  }, [user]);

  return (
    <React.Fragment>
      {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" element={
            <AuthRoute>
              <Signin2 />
            </AuthRoute>
          } />
          {protectedRoutes?.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={
                  // isAuthenticated ? (
                  //   route.element
                  // )
                  //           : (
                  // <Navigate to="/signin" replace /> // Added 'replace' prop for navigation
                  // )
                  <PrivateRoute>
                    {route.element}
                  </PrivateRoute>
                }
                key={index}
              />
            )
          })}
          {publicRoutes?.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={
                  <AuthRoute>
                    {route.element}
                  </AuthRoute>
                }
                key={index}
              />
            )
          })}
          <Route path="*" element={<NotFound />} />
          {/* <Route path="/" element={<Signin2 />} /> */}

        </Routes>
        <ToastContainer />
      {/* </BrowserRouter> */}
    </React.Fragment>

  );
}