// cricket-sport/admin/match-history

// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const CricketSportService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
  const dispatch = useDispatch();
  const axiosInstance = createAxiosInstance(bearerToken);

  const getCricketSportHistory = async ({ page, limit, searchBy }) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .get(`/cricket-sport/admin/match-history?page=${page}&limit=${limit}&matchId=${searchBy}`, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };


  // cricket-sport/update-winner
  const updateStatus = async (requestData) => {
    // const {matchId, winningSelectionId} = req.body
    return await axiosInstance
      .post(`/cricket-sport/update-winner`, requestData, { headers: {} })
      .then((response) => {
        console.log("updated =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };


  // /cricket-sport/bet-list?page=1&limit=10
  const CricketSportBetList = async({ page, limit, searchBy, transactionType, fromDate, toDate, status, user,merchantId }) => {
    // const {matchId, winningSelectionId} = req.body
    dispatch(startApiRequest());

    // .get(`cricket-sport/bet-list?&page=${page}&limit=${limit}&status=${status}&userId=${user}&searchBy=${searchBy}&fromDate=${fromDate}&toDate=${toDate}`, { headers: {} })
    return await axiosInstance
      .get(`cricket-sport/bet-list?page=${page}&limit=${limit}&status=${status}&matchId=${searchBy}&fromDate=${fromDate}&toDate=${toDate}&user=${user}&merchantId=${merchantId}`, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());

        // console.log("updated =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());

        console.error("failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  return {
    updateStatus,
    getCricketSportHistory,
    CricketSportBetList

  };
};

export default CricketSportService;
