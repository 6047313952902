import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import DashboardService from "../../service/api/dashboard.service";
import DateFilter from "./date-filter";
import { endOfMonth, format, startOfMonth } from "date-fns";
import cronParser from 'cron-parser';
import AdminCronSettings from "./AdminCronSetting";
import moment from "moment/moment";
import TransactionStatistics from "./TransactionsStatistics";
import UserStatistics from "./UserStatistics";
import { useSelector } from "react-redux";
import { Loader } from "../../common/Spinner";
import MerchantFilter from "./merchant-filter";

export default function UserMetrics() {
    const dashboardService = DashboardService();
    const [statistics, setStatistics] = useState(null);
    const today = new Date();
    const initialStartDate = startOfMonth(today);
    const initialEndDate = endOfMonth(today);
    const skin = useSelector((state) => state.ui.skin);
    const [merchantId, setMerchantId] = useState('');
    
    // const [dateRange, setDateRange] = useState({
    //     startDate: format(initialStartDate, 'yyyy-MM-dd'),
    //     endDate: format(initialEndDate, 'yyyy-MM-dd')
    // });
    const [dateRange, setDateRange] = useState({
        startDate: format(today, 'yyyy-MM-dd'),
        endDate: format(today, 'yyyy-MM-dd')
    })
    const axisColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : 'rgb(224, 224, 224)';
    const labelColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : '#41505f';


    const [cronExpression, setCronExpression] = useState(localStorage.getItem('cronExpression') || '');
    const timeoutId = useRef(null);

    useEffect(() => {
        localStorage.setItem('cronExpression', cronExpression);
        getStatistics(); // Initial fetch
        scheduleFetch();

        // Cleanup function to clear timeout if cronExpression or dateRange changes
        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [cronExpression, dateRange,merchantId]);

    // useEffect(() => {
    //     getStatistics();
    // }, [dateRange])

    const scheduleFetch = () => {
        try {
            const adjustedCron = cronExpression.split(' ').slice(1, 6).join(' ');
            const interval = cronParser.parseExpression(adjustedCron);
            const now = new Date();
            const nextExecution = interval.next().toDate();
            const delay = nextExecution - now;
            // console.log(delay, "execution delay", interval, interval.next().toDate(), adjustedCron, cronExpression)
            if (delay > 0) {
                const id = setTimeout(() => {
                    getStatistics();
                    scheduleFetch(); // Schedule next fetch
                }, delay);
                timeoutId.current = id; // Store timeout ID to clear it later
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getStatistics = async () => {
        try {
            const payload = {
                ...dateRange,
                merchantId:merchantId,
            }
            const res = await dashboardService.getBetUserMetrics(payload);
            console.log(res.data, "console from transaction metrics");
            setStatistics(res?.data); // Assuming the API response is in the expected format
        } catch (error) {
            console.error("Error fetching bet statistics", error);
        }
    }



    const processData = (data, path, valueKey = 'count') => {
        if (!data || !path) return [];

        // Access the data at the given path using optional chaining
        const targetData = path.split('.').reduce((acc, key) => acc && acc[key], data);

        if (!targetData) {
            console.warn(`No data found at path: ${path}`);
            return [];
        }

        // Map and filter the data
        return targetData.map((item) => {
            const timestamp = new Date(item._id).getTime(); // Convert _id to timestamp
            const value = Number(item[valueKey]); // Ensure the value (count or activeUsersCount) is a number
            if (isNaN(timestamp) || isNaN(value)) {
                console.warn("Invalid data point:", { dateTime: item._id, [valueKey]: item[valueKey] });
            }
            return {
                x: timestamp,
                y: value
            };
        }).filter(point => !isNaN(point.x) && !isNaN(point.y)) || [];
    };



    // console.log("Processed Data:", processedData);

    // activeUsersTrend

    const activeUsersTrendOverTimeData = {
        series: [{
            name: 'Users',
            data: processData(statistics, 'activeUsersTrend', 'activeUsersCount')
        }],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom',
                show: true, // Ensure the toolbar is enabled
                tools: {
                    download: true, // Enable the download tool
                },

                export: {
                    csv: {
                        filename: 'Active Users (Monthly)', // Custom file name
                        headerCategory: 'Date & Time', // Custom header name for category (X-axis)
                        columnHeaders: ['Date & Time', 'Active User'], // Explicitly set headers for each column
                        dateFormatter(timestamp) { // Custom date format for CSV export
                            return new Date(timestamp).toLocaleString();
                        }
                    },
                    svg: {
                        filename: 'Active Users (Monthly)', // Custom file name for SVG export
                        width: 800, // Custom width
                        height: 600, // Custom height
                        backgroundColor: '#000000', // Custom background color
                    },
                    png: {
                        filename: 'Active Users (Monthly)', // Custom file name for PNG export
                        width: 800, // Custom width
                        height: 600, // Custom height
                        backgroundColor: '#000000', // Custom background color
                    }
                },
            },
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        // title: {
        //     text: 'Transactions',
        //     align: 'left'
        // },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        yaxis: {

            title: {
                text: 'Users',
                style: {
                    color: labelColor
                }

            },
            axisBorder: {
                show: true,  // Show the axis line on the x-axis
                color: axisColor,  // Conditional color for dark/light theme
            },
            axisTicks: {
                show: true,  // Show the ticks on the x-axis
                color: axisColor,  // Conditional color for dark/light theme
            },
            labels: {
                formatter: function (val) {
                    return (val).toFixed(0);
                },
                style: {
                    colors: labelColor,  // Conditional color for dark/light theme
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,


                }
            }

        },
        xaxis: {
            type: 'datetime',
            axisBorder: {
                show: true,
                color: axisColor,  // Conditional color for dark/light theme
                height: 2,
            },
            axisTicks: {
                color: axisColor,
                show: true  // Removes the ticks on the y-axis
            },
            labels: {
                style: {
                    colors: labelColor,  // Conditional color for dark/light theme
                }
            }

        },
        tooltip: {
            shared: false,
            theme: skin == "dark" ? "dark" : "light",
            y: {
                formatter: function (val) {
                    return (val).toFixed(0)
                }
            }
        },
        grid: {
            show: true, // Enable grid to allow border customization
            borderColor: axisColor, // Set the color for axis border lines
            strokeDashArray: 0, // Solid border lines
            xaxis: {
                lines: {
                    show: false // Hide vertical grid lines
                }
            },
            yaxis: {
                lines: {
                    show: false // Hide horizontal grid lines
                }
            },
            padding: {
                left: 13,
                right: 10,
                top: 0,
                bottom: 0
            }
        },
    };


    const handleDateChange = (range) => {
        setDateRange(range);
    };

    // const processedData = statistics?.userGrowthTrend?.map((item) => {
    //     console.log(item)
    //     const timestamp = new Date(item.dateTime).getTime(); // Convert dateTime to timestamp
    //     const value = Number(item.successRate); // Ensure successRate is a number
    //     if (isNaN(timestamp) || isNaN(value)) {
    //         console.warn("Invalid data point:", { dateTime: item.dateTime, successRate: item.successRate });
    //     }
    //     return {
    //         x: timestamp,
    //         y: value
    //     };
    // }).filter(point => !isNaN(point.x) && !isNaN(point.y)) || [];



    const options = {
        series: [{
            name: 'Users',
            data: processData(statistics, 'userGrowthTrend')
        }],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom',
                show: true, // Ensure the toolbar is enabled
                tools: {
                    download: true, // Enable the download tool
                },

                export: {
                    csv: {
                        filename: 'User Growth Trend (Monthly)', // Custom file name
                        headerCategory: 'Date & Time', // Custom header name for category (X-axis)
                        columnHeaders: ['Date & Time', 'User'], // Explicitly set headers for each column
                        dateFormatter(timestamp) { // Custom date format for CSV export
                            return new Date(timestamp).toLocaleString();
                        }
                    },
                    svg: {
                        filename: 'User Growth Trend (Monthly)', // Custom file name for SVG export
                        width: 800, // Custom width
                        height: 600, // Custom height
                        backgroundColor: '#000000', // Custom background color
                    },
                    png: {
                        filename: 'User Growth Trend (Monthly)', // Custom file name for PNG export
                        width: 800, // Custom width
                        height: 600, // Custom height
                        backgroundColor: '#000000', // Custom background color
                    }
                },
            },
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        // title: {
        //     text: 'Transactions',
        //     align: 'left'
        // },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        yaxis: {

            title: {
                text: 'Users',
                style: {
                    color: labelColor
                }
            },
            axisBorder: {
                show: true,  // Show the axis line on the x-axis
                color: axisColor,  // Conditional color for dark/light theme
            },
            axisTicks: {
                show: true,  // Show the ticks on the x-axis
                color: axisColor,  // Conditional color for dark/light theme
            },
            labels: {
                formatter: function (val) {
                    return (val).toFixed(0);
                },
                style: {
                    colors: labelColor,  // Conditional color for dark/light theme
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400
                }
            }

        },
        xaxis: {
            type: 'datetime',
            axisBorder: {
                show: true,
                color: axisColor,  // Conditional color for dark/light theme
                height: 2,
            },
            axisTicks: {
                color: axisColor,
                show: true  // Removes the ticks on the y-axis
            },
            labels: {
                style: {
                    colors: labelColor,  // Conditional color for dark/light theme
                }
            }

        },
        tooltip: {
            // title:'Users',
            shared: false,
            theme: skin == "dark" ? "dark" : "light",
            y: {
                formatter: function (val) {
                    return (val).toFixed(0)
                }
            }
        },
        grid: {
            show: true, // Enable grid to allow border customization
            borderColor: axisColor, // Set the color for axis border lines
            strokeDashArray: 0, // Solid border lines
            xaxis: {
                lines: {
                    show: false // Hide vertical grid lines
                }
            },
            yaxis: {
                lines: {
                    show: false // Hide horizontal grid lines
                }
            },
            padding: {
                left: 13,
                right: 10,
                top: 0,
                bottom: 0
            }
        },

    };


    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="/bet-statics">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">User Metrics</li>
                        </ol>
                        <h4 className="main-title mt-2 mb-0">User Metrics</h4>

                    </div>
                    <div className="d-flex flex-wrap gap-2 mt-3 mt-md-0">
                        <MerchantFilter merchantId={merchantId} setMerchantId={setMerchantId}/>
                        <DateFilter onDateChange={handleDateChange} />
                        <AdminCronSettings onSave={(expression) => setCronExpression(expression)} />
                    </div>
                </div>

                {statistics ? <>
                    <UserStatistics statistics={statistics} />
                    <Row className="mb-4">
                        <Col lg={6} md={12} className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Users Growth Trend (Monthly)
                                    </Card.Title>
                                    <ReactApexChart options={options} series={options.series} type="area" height={350} />
                                    {/* <ReactApexChart options={userGrowthTrendOverTimeData?.options} series={userGrowthTrendOverTimeData?.series} type="line" height={350} /> */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={12} className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Active Users (Monthly)
                                    </Card.Title>
                                    <ReactApexChart options={activeUsersTrendOverTimeData} series={activeUsersTrendOverTimeData?.series} type="area" height={350} />

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row></> : <>
                    <div className="d-flex justify-content-center align-content-center " style={{ height: '60vh' }} >
                        <Loader />
                    </div>
                </>}

                {/* <Footer /> */}
            </div>
        </React.Fragment>
    );
}
