import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import MerchantService from "../../service/api/merchant.service";
import { useNavigate, useLocation } from "react-router-dom";
import { BorderSpinner } from "../../common/Spinner";
import { darkModeStyles, lightModeStyles } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import Select from "react-select"; // For multiselect input
import { Table } from "react-bootstrap";
import '../../common/table.css'
import { formatCurrency } from "../../helper/CurrencyFormate";
const ViewNewMerchant = () => {
  const { skin, setSkin } = useSkinSwitcher();
  const merchantService = MerchantService();
  const location = useLocation();
  const { id } = location.state;
  const theme = useSelector((state) => state.ui.skin);
  let category = ["category1", "category2"];
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGamesAndCategory, setSelectedGamesAndCategory] = useState(
    {
      games: [],
      category: [],
    }
  )
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    domain: "",

    role: "",
    status: "",
    createdAt: "",
    supportType: "",
  });
  const [balancesTableData, setBalancesTableData] = useState([]);
  useEffect(() => {
    getMerchantDetail();
    getAssignedGames(id);
  }, []);

  const getMerchantDetail = async () => {
    try {

      const res = await merchantService.getMerchantById(id);
      if (res?.code === 200) {
        const data = {
          name: res.data.name,
          email: res.data.email,
          phone: res.data.phone.substring(res.data.phone.length - 10),

          supportNumber: res.data.supportNumber.substring(res.data.supportNumber.length - 10),
          depositeNumber: res.data.depositeNumber.substring(res.data.depositeNumber.length - 10),
          supportType:res?.data?.supportType,

          domain: res.data.domain,
          adminDomain:res?.data?.adminDomain,
          role: res.data.role,
          status: res.data.status === 1 ? "Active" : "Inactive",
          createdAt: new Date(res.data.createdAt).toLocaleString(),
          metaKeywords: res.data.metaKeywords,
          metaDescription: res.data.metaDescription,
          metaTitle: res.data.metaTitle,
          logo: res.data.logo,
        };
        setImagePreview(res?.data?.logo? `${process.env.REACT_APP_IMAGE_URL}/${res?.data?.logo}`:null);

        setFormData(data);
        // Generate grouped balances data for table
        const groupedData = res.data.balances.reduce((acc, item) => {
          const { currencyCodes, name, currencySymbols } = item.currencyId;

          if (!acc[currencyCodes]) {
            acc[currencyCodes] = {
              currency: {
                name,
                code: currencyCodes,
                symbol: currencySymbols,
              },
              balances: {
                deposit: 0,
                bonus: 0,
                winnings: 0,
              },
            };
          }

          // Map the `type` field to the corresponding balance type
          if (item.type === 0) {
            acc[currencyCodes].balances.deposit = item.balance;
          } else if (item.type === 2) {
            acc[currencyCodes].balances.bonus = item.balance;
          } else if (item.type === 1) {
            acc[currencyCodes].balances.winnings = item.balance;
          }

          return acc;
        }, {});

        setBalancesTableData(Object.values(groupedData));
      }
      else {
        throw new Error(res.message);
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAssignedGames = async (id) => {
    try {
      const res = await merchantService.getAssignedGames(id);
      if (res?.code === 200) {
        const responseGames = res?.data[0]?.games || [];
        // Group games by category with corresponding format
        const categoryOptions = responseGames?.reduce((acc, item) => {
          const categoryId = item.categoryId.id;
          const categoryName = item.categoryId.name;

          // Find if this category already exists
          let category = acc.find(cat => cat.value === categoryId);
          if (!category) {
            // If not, create a new category
            category = {
              label: categoryName,
              value: categoryId,
              games: [],
            };
            acc.push(category);
          }

          // Add the game to the corresponding category
          category.games.push({
            label: item.gameId.name,
            value: item.gameId.id,
            category: categoryId, // Ensure the correct category ID
          });

          return acc;
        }, []);

        // Flatten games to set as selected games
        const games = categoryOptions.flatMap(cat => cat.games);

        // Set states for categories and games
        setSelectedGamesAndCategory({
          games: games,
          category: categoryOptions,
        });


      } else {
        console.error(res?.message);
      }
    } catch (error) {
      console.error("Error fetching assigned games:", error);
    }
  };

  console.log(balancesTableData, "balances data")

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between ">
              <h4 className="card-title w-100">View Merchant</h4>
              <Button variant="primary" type="button" onClick={() => navigate("/merchant-management")}>
                Back
              </Button>
            </div>
          </Card.Header>
          <Card.Body>

            {isLoading ? <div className="d-flex mt-5 justify-content-center align-content-center">
              <BorderSpinner />
            </div> : (<Form className="row">
              <Col md={4} className="mt-3">
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" value={formData.name} disabled />
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" value={formData.email} disabled />
                </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                <Form.Group controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" value={formData?.phone} disabled />
                </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                <Form.Group controlId="supportNumber">
                  <Form.Label>Support Type</Form.Label>
                  <Form.Select
                      name="supportType"
                      disabled
                      value={formData.supportType}
                     
                    >
                      {/* ["whatsapp", "telegram", "intercom"] */}
                      <option value="">Select Type</option>
                      <option value="whatsapp">whatsapp</option>
                      <option value="telegram">Telegram</option>
                      <option value="intercom">Intercom</option>
                      <option value="kripabetintercom">Kripabet Intercom</option>

                    </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="supportNumber">
                  <Form.Label>Support Mobile Number</Form.Label>
                  <Form.Control type="text" value={formData?.supportNumber} disabled />
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="depositeNumber">
                  <Form.Label>Deposit Mobile Number</Form.Label>
                  <Form.Control type="text" value={formData.depositeNumber} disabled />
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="domain">
                  <Form.Label>User Domain </Form.Label>
                  <Form.Control type="text" value={formData.domain} disabled />
                </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                <Form.Group controlId="domain">
                  <Form.Label>Merchant Domain</Form.Label>
                  <Form.Control type="text" value={formData?.adminDomain} disabled />
                </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                <Form.Group controlId="metaTitle">
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control type="text" value={formData.metaTitle} disabled />
                </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                <Form.Group controlId="metaKeywords">
                  <Form.Label>Meta Keywords</Form.Label>
                  <Form.Control type="text" value={formData.metaKeywords} disabled />
                </Form.Group>
              </Col>
                <Col md={4} className="mt-3">
                <Form.Group controlId="metaDescription">
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control as={'textarea'} rows={4} type="text" value={formData.metaDescription} disabled />
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="role">
                  <Form.Label>Role</Form.Label>
                  <Form.Control type="text" value={formData.role} disabled />
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="status">
                  <Form.Label>Status</Form.Label>
                  <Form.Control type="text" value={formData.status} disabled />
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="createdAt">
                  <Form.Label>Account Created</Form.Label>
                  <Form.Control type="text" value={formData.createdAt} disabled />
                </Form.Group>
                </Col>
                
                <Col md={4} className="mt-3">
                  <Form.Group>
                    <Form.Label>Logo</Form.Label>
                    <div className="image-upload-wrapper">
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className="img-thumbnail"
                          style={{ width: "100px", height: "100px" }}
                        />
                      ) : (
                        <div className="image-placeholder">
                          Logo Not Available
                        </div>
                      )}
                     
                    </div>
                  </Form.Group>
                </Col>


              {/* <Col md={4} className="mt-3">
                <Form.Group controlId="category">
                  <Form.Label>Assigned Category</Form.Label>
                  <Select
                    styles={theme === 'dark' ? darkModeStyles : lightModeStyles}
                    // disabled={true}
                    isMulti
                    value={selectedGamesAndCategory.category}
                    isDisabled
                  />

                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="games">
                  <Form.Label>Assigned Games</Form.Label>
                  <Select
                    styles={theme === 'dark' ? darkModeStyles : lightModeStyles}
                    isDisabled

                    isMulti
                    value={selectedGamesAndCategory.games}

                  />
                  
                </Form.Group>
              </Col> */}
              <Col md={12} className="mt-5">
                <h5>Assigned Games & Categories</h5>
                <Col md={12} className="mt-3">
                  <Form.Group controlId="category">
                    <Form.Label className="" as={'strong'} >Assigned Categories</Form.Label>
                    {selectedGamesAndCategory.category.length > 0 ? (
                      <div className="p-2 border rounded">
                        <ul className="list-unstyled mb-0">
                          {selectedGamesAndCategory.category.map((category) => (
                            <li key={category.value}>
                              <span>{category.label}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p className="text-muted">No categories assigned.</p>
                    )}
                  </Form.Group>
                </Col>

                <Col md={12} className="mt-3">
                  <Form.Group controlId="games">
                    <Form.Label as={'strong'}>Assigned Games</Form.Label>
                    {selectedGamesAndCategory.games.length > 0 ? (
                      <div className="p-2 border rounded">
                        <ul className="list-unstyled mb-0">
                          {selectedGamesAndCategory.games.map((game) => (
                            <li key={game.value}>
                              {game.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p className="text-muted">No games assigned.</p>
                    )}
                  </Form.Group>
                </Col>
              </Col>

              <Col md={12} className="mt-5">
                <h5>Balances</h5>
                <Table className={`table-bordered  table-centered ${theme === 'dark' ? 'customTable' : ''} mb-0`}>
                  <thead>
                    <tr>
                      <th>Currency</th>
                      <th>Deposit</th>
                      <th>Bonus</th>
                      <th>Winnings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {balancesTableData.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {data.currency.name} ({data.currency.code})
                        </td>
                        <td>{formatCurrency(data.balances.deposit, data.currency.code)}</td>
                        <td>{formatCurrency(data.balances.bonus, data.currency.code)}</td>
                        <td>{formatCurrency(data.balances.winnings, data.currency.code)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>

              {balancesTableData?.length === 0 && <div className="d-flex  justify-content-center my-3">
                <h6> No Records Found</h6>
              </div>}
            </Form>)}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ViewNewMerchant;
