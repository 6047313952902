import React, { useRef, useState, useEffect } from 'react';
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import ReactSelect from "react-select";
import { useSelector } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import {
    darkModeStyles,
    darkModeStyles2,
    lightModeStyles,
    lightModeStyles2,
} from "./reactSelectStyles";
import './table.css'
import GameService from '../service/api/game.service';
import GameSettingService from '../service/api/game-setting.service';
import { AsyncPaginate } from "react-select-async-paginate";
import MerchantService from '../service/api/merchant.service';
import UserService from '../service/api/user.service ';
// import MerchantService from "../../service/api/merchant.service";


const TableFilter = ({ userList, tableType, showPicker, transactionType, setPagination, setShowPicker,status,userId }) => {
    const theme = useSelector((state) => state.ui.skin);
    
    const gameService = GameSettingService();
    const merchantService = MerchantService();
    const userService = UserService();
    const isLoading = useSelector((state) => state.state.loading);
    // const formatDate = (date) => date ? format(date, "dd-MM-yyyy") : '';
    const formatDate = (date) => date ? format(date, "yyyy-MM-dd") : '';
   console.log(status,userId,"info for filter")
    const transactionTypeRef = useRef(null);
    const statusRef = useRef(null);
    const userRef = useRef(null);
    const gameRef = useRef(null);
    const merchantRef = useRef(null);
    const [merchantOption, setMerchantOption] = useState(null);
    const [userOption, setUserOption] = useState(null);

    const pickerRef = useRef(null);

    const [state, setState] = useState([
        {
            startDate: Date.now(),
            endDate: null,
            key: 'selection'
        }
    ]);

    const [filters, setFilters] = useState({
        transactionType: transactionType,
        status: status ||"",
        user: userId ||"",
        gameId: "",
        searchBy: "",
        merchantId:"",
    });

    const [gameListOptions, setGameListOptions] = useState([]);

   console.log(filters,userList,"hello world filters")

    useEffect(() => {
        const getList = async () => {
            try {

                const res = await gameService.getGameListDropdown();
                if (res?.code === 200) {
                    console.log('Game list', res);
                    const list = res.data.map((item) => ({
                        label: item.name,
                        value: item.id
                    }))
                    setGameListOptions(list);
                }
                else {
                    console.log('Game list', res);
                }
            } catch (error) {
                console.error(error);
            }
        }

        getList();
    }, [])


   

   


    const loadOptionsForMerchant = async (search, loadedOptions, { page }) => {
        try {
            const response = await merchantService.getMerchants({ 
                page, 
                limit: 10, 
                searchBy:search 
            }); // Adjust the API endpoint as needed
            
            if (response.code === 200) {
                return {
                    options: response.data?.docs.map((merchant) => ({
                        value: merchant.id,
                        label: `${merchant.name}(${merchant.email})`,
                    })),
                    hasMore: response.data.hasNextPage ,
                    additional: {
                        page: page + 1,
                    },
                };
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            console.error("Error fetching merchant list:", error);
            return {
                options: [],
                hasMore: false,
            };
        }
    };

    const handleChange = (selectedOption) => {
        setMerchantOption(selectedOption);
        // setMerchantId(selectedOption ? selectedOption.value : "");
        setFilters((prev) => ({ ...prev, merchantId:selectedOption ? selectedOption.value : "" }))
    };

    const loadOptionsForUser = async (search, loadedOptions, { page }) => {
        try {
            const response = await userService.getUser({ 
                page, 
                limit: 10, 
                searchBy:search 
            }); // Adjust the API endpoint as needed
            
            if (response.code === 200) {
                return {
                    options: response.data?.docs.map((merchant) => ({
                        value: merchant.id,
                         label: `${merchant.name}(${merchant.email})`
                    })),
                    hasMore: response.data.hasNextPage ,
                    additional: {
                        page: page + 1,
                    },
                };
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            console.error("Error fetching merchant list:", error);
            return {
                options: [],
                hasMore: false,
            };
        }
    };

    const handleUserChange = (selectedOption) => {
        setUserOption(selectedOption);
        // setMerchantId(selectedOption ? selectedOption.value : "");
        setFilters((prev) => ({ ...prev, user:selectedOption ? selectedOption.value : "" }))
    };

   


    const handleReset = () => {
        const defaultFilters = {
            transactionType: transactionType,
            status: "",
            user: "",
            gameId: "",
            searchBy: "",
            merchantId:""
        };
        setFilters(defaultFilters);
        setPagination((preValue) => ({
            ...preValue,
            ...defaultFilters,
            fromDate: "",
            toDate: ""
        }));
        clearRange();

        if (transactionTypeRef.current) transactionTypeRef.current.clearValue();
        if (statusRef.current) statusRef.current.clearValue();
        if (userRef.current) userRef.current.clearValue();
        if (gameRef.current) gameRef.current.clearValue();
        if (merchantRef.current) merchantRef.current.clearValue();

        
    };

    const handleSubmit = () => {
        setPagination((preValue) => ({
            ...preValue,
            page: 1,
            transactionType: filters.transactionType,
            status: filters.status,
            user: filters.user,
            searchBy: filters.searchBy,
            gameId: filters.gameId,
            merchantId:filters.merchantId,
            fromDate: state[0].startDate === null ? '' : formatDate(state[0].startDate),
            toDate: state[0].endDate === null ? '' : formatDate(state[0].endDate)
        }));
    };

    const handleRangeChange = (ranges) => {
        setState([ranges.selection]);
    };

    const clearRange = () => {
        setState([{ startDate: Date.now(), endDate: null, key: 'selection' }]);
        setShowPicker(false);
    };

    const statusOptions = [
        { value: "", label: "All" },
        { value: "0", label: "Pending" },
        { value: "1", label: "Successful" },
        { value: "2", label: "Failed" },
        { value: "3", label: "Initiated" },
    ];

    const statusOptions2 = [
        { value: "", label: "All" },
        { value: "0", label: "Lost" },
        { value: "1", label: "Won" },
    ];

    const statusOptions3 = [
        { value: "", label: "All" },
        { value: "0", label: "Lost" },
        { value: "1", label: "Won" },
        { value: "2", label: "Pending" },
    ];
    const statusOptions4 = [
        { value: "", label: "All" },
        { value: false, label: "Lost" },
        { value: true, label: "Won" },
       
    ];

    const transactionTypeOptions = [
        { value: "", label: "All" },
        { value: "credit", label: "Credit" },
        { value: "debit", label: "Debit" },
    ];


    
    const renderMerchantFilter = () => (
        <Col xs={12} md={6} lg={3}>
            <AsyncPaginate
                selectRef={merchantRef}
                // ref={merchantRef}
                value={merchantOption}
                loadOptions={loadOptionsForMerchant}
                onChange={handleChange}
                additional={{
                    page: 1,
                }}
                styles={theme === 'dark' ? darkModeStyles : lightModeStyles}

                placeholder="Select a Merchant"
                isClearable
            />
        </Col>
    );
    const renderSearchInput = (placeholder) => (
        <Col xs={12} md={6} lg={3}>
            <div className="custom-form-css form-search position-relative">
                <input
                    type="search"
                    className="form-control"
                    placeholder={placeholder}
                    value={filters.searchBy}
                    onChange={(e) => setFilters((prev) => ({ ...prev, searchBy: e.target.value }))}
                />
                <i className="ri-search-line"></i>
            </div>
        </Col>
    );

    const renderTransactionTypeFilter = () => (
        <Col xs={12} md={6} lg={3}>
            <ReactSelect
                options={transactionTypeOptions}
                isClearable={true}
                ref={transactionTypeRef}
                styles={theme === "dark" ? darkModeStyles : lightModeStyles}
                placeholder="Select Transaction Type"
                onChange={(value) => setFilters((prev) => ({ ...prev, transactionType: value?.value }))}
            />
        </Col>
    );

    const renderStatusFilter = (options) => (
        <Col xs={12} md={6} lg={3}>
            <ReactSelect
                options={options}
                // isOptionSelected={}
                isClearable={true}
                ref={statusRef}
                styles={theme === "dark" ? darkModeStyles : lightModeStyles}
                placeholder="Select Status"
                defaultValue={options.find(option => option.value === filters.status)} // Set default value for status
                onChange={(value) => setFilters((prev) => ({ ...prev, status: value?.value }))}
            />
        </Col>
    );

    const renderUserFilter = () => (
        <Col xs={12} md={6} lg={3}>
            <AsyncPaginate
                selectRef={userRef}
                // ref={merchantRef}
                value={userOption}
                loadOptions={loadOptionsForUser}
                onChange={handleUserChange}
                additional={{
                    page: 1,
                }}
                styles={theme === 'dark' ? darkModeStyles : lightModeStyles}

                placeholder="Select a User"
                isClearable
            />
            {/* <ReactSelect
                options={userList}
                isClearable={true}
                ref={userRef}
                styles={theme === "dark" ? darkModeStyles : lightModeStyles}
                placeholder="Select User"
                value={userList.find(option => option.value === filters.user)}
                defaultValue={userList.find(option => option.value === filters.user)} // Set default value for user
                onChange={(value) => setFilters((prev) => ({ ...prev, user: value?.value }))}
            /> */}
        </Col>
    );

    const renderGameFilter = () => (
        <Col xs={12} md={6} lg={3} >

            <ReactSelect
                styles={theme === 'dark' ? darkModeStyles : lightModeStyles}
                name="gameId"
                options={gameListOptions}
                isClearable={true}
                ref={gameRef}
                // isMulti
                placeholder="Select Game"
                onChange={(value) => setFilters((prev) => ({ ...prev, gameId: value?.value }))}
            />

        </Col>
    );

    const renderFilters = () => {
        switch (tableType) {
            case 'user-list':
                return (
                    <>
                         {renderMerchantFilter()}
                    </>
                )
            case 'crashHistory':
                return (
                    <>
                        {renderSearchInput('Search by round Id')}
                        {/* {renderStatusFilter(statusOptions2)} */}
                    </>
                );
            case 'betHistory':
                return (
                    <>
                        {renderSearchInput('Search by round Id')}
                        {renderStatusFilter(statusOptions2)}
                        {renderUserFilter()}
                        {renderMerchantFilter()}
                        {renderGameFilter()}
                    </>
                );
            case 'betHistoryCricketSports':
                return (
                    <>
                        {renderSearchInput('Search by match Id')}
                        {renderStatusFilter(statusOptions3)}
                        {renderUserFilter()}
                        {renderMerchantFilter()}
                        {/* lottery-ticket-list */}
                    </>
                );
                case 'lottery-ticket-list':
                    return (
                        <>
                            {/* {renderSearchInput('Search by match Id')} */}
                            {renderStatusFilter(statusOptions4)}
                            {renderUserFilter()}
                            {renderMerchantFilter()}
                            {/* lottery-ticket-list */}
                        </>
                    );

            case 'wallet':
                return (
                    <>
                        {renderSearchInput('Search by Transaction Id')}
                        {renderTransactionTypeFilter()}
                        {renderStatusFilter(statusOptions)}
                        {renderMerchantFilter()}
                        
                    </>
                )

            default:
                return (
                    <>
                        {renderSearchInput('Search by Transaction Id')}
                        {!transactionType && renderTransactionTypeFilter()}
                        {renderStatusFilter(statusOptions)}
                        {renderUserFilter()}
                        {renderMerchantFilter()}
                    </>
                );
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerRef]);

    return (
        <div className='p-1 mt-4 rounded'>
            <Row className="g-3">
                {renderFilters()}
                <Col xs={12} md={6} lg={3} className="position-relative">
                    <div className="position-relative form-search custom-form-css">
                        <input
                            type="text"
                            className="form-control"
                            value={state[0]?.endDate === null ? "Select date range" : `${formatDate(state[0].startDate)} to ${formatDate(state[0].endDate)}`}
                            onClick={() => setShowPicker(!showPicker)}
                            // readOnly
                        />
                        {state[0]?.endDate !== null && <Button variant="danger" className="position-absolute top-50 end-0 translate-middle-y me-0" onClick={clearRange}>X</Button>}
                        {showPicker && (
                            <div ref={pickerRef} className="position-absolute z-3">
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={handleRangeChange}
                                    moveRangeOnFirstSelection={true}
                                    ranges={state}
                                    maxDate={new Date()} // Disable future dates
                                    className={`date-range-picker ${theme === "dark" ? "date-range-picker-dark " : "date-range-picker-light"}`}
                                />
                            </div>
                        )}
                    </div>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <div className="d-flex gap-2">
                        <Button variant="primary" onClick={handleSubmit}>{"Apply"}</Button>
                        <Button variant="secondary" onClick={handleReset}>Reset</Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default TableFilter;
