import React from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import CommonPageEditor from "./Editor";

export default function AboutUs() {
  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <CommonPageEditor
          apiEndpoint="static-content/page/aboutus"
          pageTitle="About Us"
        />
      </div>
    </React.Fragment>
  );
}




