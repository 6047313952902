import React, { useEffect, useRef, useState } from "react";
import Header from "../../../layouts/Header";
import TableWithPagination from "../../../common/table";
import AuthService from "../../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
// import "../game-management/user.css";
import { Button, Card, Row, Col, Nav, ListGroup, Table, Badge } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
// import "cryptofont/css/cryptofont.css";
import Sidebar from "../../../layouts/Sidebar";
import { toast } from "react-toastify";
import PageBreadcrumb from "../../../common/PageBreadcrum";
import useSkinSwitcher from "../../../common/skin";
import StaticPagesService from "../../../service/api/static.pages.service";

export default function FAQList() {
  // const gameService = GameService();
  // const gameSettingService = GameSettingService();
  const staticPageService = StaticPagesService();
  const authService = AuthService();
  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [fAQList, setFAQList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: '',
    limit: 10,
    totalDocs: 0
  });
  const [columns, setColumns] = useState(["question", "answer", "id"]);
  const [merchantOption, setMerchantOption] = useState(null);


  useEffect(() => {
    getList();
    authService.getLoginData()
  }, []);

  const getList = async () => {
    const res = await staticPageService.getFaqList({
      page: pagination?.page,
      limit: pagination?.limit,
      searchBy: pagination?.searchBy
    });
    if (res?.code === 200) {
      console.log(res, "========Response from faq")
      setFAQList(res?.data?.docs);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pages: res?.data.pages,
        totalDocs: res?.data.totalDocs,
        limit: res?.data.limit
      }));
    } else {
      console.error(res?.message);
      // toast.error(res?.message);
    }
  };

  useEffect(() => {
    getList();
    authService.getLoginData()
  }, [pagination.searchBy, pagination.page]);



  // Define HTML for action column
  const getActionDropdown = (id, status) => {
    console.log("id", id);
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
          {/* <Dropdown.Item onClick={() => handleStatusUpdate(id,status)}>
          {status === 1 ? "Deactivate" : "Activate"}
          </Dropdown.Item> */}
          {/* <Dropdown.Item onClick={() => handleDelete(id)}>Delete</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    );
  };



  const handleUpdate = (id) => {
    const state = { id: id };
    navigate(`/faq/edit`, { state });
  };
  const handleView = (id) => {
    const state = { id: id };
    navigate(`/faq/view`, { state });
  };



  const handleDelete = async (id) => {
    // const state = {id: id}
    // navigate(`/role-management/edit`, {state})
    // const role = gameList?.filter((item) => item.id === id);
    // const res = await gameSettingService.deleteGame(id);
    // if (res?.code == 200) {
    //   toast.success(res?.message);
    //   getList();
    // } else {
    //   console.error(res?.message);
    //   toast.error(res?.message);
    // }
  };

  const getMaintainanceBadge = (status) => {
    if (status) {
      return (
        <Badge bg={"success"} className="badge badge-success">
          Yes
        </Badge>
      );
    } else {
      return (
        <Badge bg={"pink"} className="badge badge-danger">
          No
        </Badge>
      );
    }
  };



  // Map the columns to include HTML for status badges
  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "id") {
      return {
        header: " ",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row?.status), // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "question") {
      return {
        header: "Question",
        accessor: column,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "400px",  // Adjust the width as needed
              }}
              title={value}
            >
              {value || "N/A"}
            </div>
          );
        }, // Render action
      }
    }
    else if (column.toLowerCase() === "answer") {
      return {
        header: "Answer",
        accessor: column,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "400px",  // Adjust the width as needed
              }}
              title={value}
            >
              {value || "N/A"}
            </div>
          );
        }, // Render action
      }
    }

  });


  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();
    // if (value.length > 3 || value.length === 0) {
    // }
    setPagination((prev) => ({ ...prev, searchBy: value }));
  };

  const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>

          <div className=" align-items-center ">
            <Card.Header>

              <PageBreadcrumb title="FAQ's" />
              {/* <h1>Game Management</h1> */}

            </Card.Header>
            <TableWithPagination
              data={[...fAQList]}
              itemsPerPage={pagination?.limit}
              columns={columnsWithHTML}
              currentPage={pagination?.page}
              totalPages1={pagination?.totalPages}
              totalData={pagination?.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              handleSearchChange={debouncedHandleSearchChange}
              moduleId={9}
              tableType="faq-list"
              redirectUrl={'/faq/add'}
            />
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}


